import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../Common/CommonHooks';
import { useBetSlip } from '../BetSlipHooks';
import { GetFootballConfig } from '../../Common/ConfigHelper';
import { GetChannelPara } from '../../Common/ChannelParaFunc';
import { textFormatAmountFn, flexiFontSize } from '../../Common/GlobalFunc';
import { BetLineConfirmationHeader } from '../BetSlipBase';
import { useExpend } from './useExpend';
import CheckBox from '../../Common/ui-components/CheckBox';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import { fbCalMultiSel, getHandicapPoolsMaxOdds, getLinePoolsMaxOdds } from '../BetlinesValidFunc';
import { getNumberSuffix } from '../../Football/Common/MatchBase';
import { isCRSOtherSels } from '../../Football/Common/CRSTable';
import { getPersonalSettings } from '../../Home/PersonalSettings/config';
import { getOddsArrowCss } from '../useBetSlipPush/common';

const FBBetLineTemplate = ({
    betLineData,
    panelAllupObj,
    panelAllupCheckFn,
    isShowPreviewInfo,
    backBetlines,
    setIsShowError,
    isShowConfirmation
}) => {
    const { i18n, t } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const { isExpended, handleClickExpendBox } = useExpend({ isShowPreviewInfo, isShowConfirmation });
    const [unitBet, setUnitBet] = useState(10);
    const [checkedAllUp, setCheckedAllUp] = useState(false);
    const [isDim, setIsDim] = useState(false);
    const [isResendBetLine, setIsResendBetLine] = useState(false);
    const FBconfig = GetFootballConfig();
    const { MixAllup6LegPools, TournamentAllupPools, AllPoolsMinBet, NTSPools, GoalLinePools, HandicapPools } = FBconfig;
    const { removeBetLinesItem, updateBetLinespData, updateResendBetLine, GetXPoolAllUpEnabled, TranslateBetType } = useBetSlip();
    const betObj = betLineData.betObj;
    const tournamentName = i18n.language === 'en' ? betObj.tournamentNameEN : betObj.tournamentNameCH;
    const matchTeam = i18n.language === 'en' ? betObj.matchTeamEN : betObj.matchTeamCH;
    const matchTeamName = betLineData?.betObj?.betType === 'TPS' ? t('LB_FB_TPS_TEAM') : matchTeam;
    const NumberOfBet = betObj?.noOfComb || 1;
    const specialsLine = betObj?.specialLine;
    useEffect(() => {
        setUnitBet(betLineData.unitBet);
    }, []);

    useEffect(()=>{
        if(betObj.isStopSell){
            setCheckedAllUp(false);
        }
    },[betObj.isStopSell, betLineData])

    useEffect(() => {
        setIsResendBetLine(false);
        if (isShowConfirmation) {
            const isAutoAcceptance = betLineData.isAutoAcceptance;
            const showResendControlBtn = betLineData.showResendControlBtn;
            setIsResendBetLine(isAutoAcceptance || showResendControlBtn);
            setUnitBet(betLineData.unitBet);
        }
    }, [isShowConfirmation, betLineData]);

    useEffect(() => {
        let bool = false;
        if (panelAllupObj?.subTypeList.length > 0) {
            if (!panelAllupObj.subTypeList.includes(betObj.betType)) {
                bool = true;
            }
            const { allupCheckList, allupMaxLeg, uniqueMatchIdList, allupGroup } = panelAllupObj;
            for (let i = 0; i < allupCheckList.length; i++) {
                const element = allupCheckList[i];

                if (allupGroup === 2) {
                    let isAlupPoolOfTourn = GetChannelPara('CrossTourn') == 1;
                    betObj.raceno = betObj?.combs?.[0]?.currentPool?.instNo;
                    if (
                        (element.betType !== betObj.betType && element.raceno == betObj.raceno) ||
                        (!isAlupPoolOfTourn && element.betType !== betObj.betType) ||
                        element.matchId !== betObj.matchId
                    ) {
                        bool = true;
                        break;
                    }
                } else if (allupGroup === 1) {
                    if (element.betType != betObj.betType && (!GetXPoolAllUpEnabled(element.betType) || !GetXPoolAllUpEnabled(betObj.betType))){
                        bool = true
                        break
                    }
                    if (element.matchId === betObj.matchId && element.betType !== betObj.betType) {
                        bool = true;
                        break;
                    }
                }
            }
            let uniqueMatchIdCheckList = uniqueMatchIdList;
            if (uniqueMatchIdCheckList.length >= 6 && !bool) {
                if (allupMaxLeg === 6) {
                    bool = !uniqueMatchIdCheckList.includes(betObj.matchId) ? true : false;
                } else {
                    bool = MixAllup6LegPools.includes(betObj.betType) ? true : false;
                    if (uniqueMatchIdCheckList.length >= 8 && ((allupGroup === 1 && !uniqueMatchIdCheckList.includes(betObj.matchId)) || (allupGroup === 2 && !uniqueMatchIdCheckList.includes(betObj?.combs?.[0]?.currentPool?.instNo)))) {
                        bool = true;
                    }
                }
            }
        }
        setIsDim(bool);

        if (panelAllupObj?.subTypeList.length === 0 && checkedAllUp) {
            setCheckedAllUp(false);
        }
    }, [panelAllupObj]);

    const onFocusBetTextbox = (e) => {
        // if ( isMobileSize ) {
        //     e.target.select();
        // }
        // e.target.value = '';
        if ( isMobileSize ) {
            e.preventDefault();
            e.target.setAttribute("data-init",1);
            e.target.removeAttribute('maxLength')
            e.target.setSelectionRange(10, 10)
            setTimeout(function(){
                e.target.setSelectionRange(10, 10)
            });
        }
    };

    const onClickBetTextbox = (e) => {
        if ( isMobileSize ) {
            e.target.setSelectionRange(10, 10)
            setTimeout(function(){
                e.target.setSelectionRange(10, 10)
            });
        }
    };

    const onInputUnitBet = (e) => {
        let value = e.target.value;
        if(isMobileSize && e.target.getAttribute("data-init") == 1) {
            if(e.nativeEvent.data !== null) {
                value = e.nativeEvent.data;
            }
            e.target.setAttribute("data-init",0);
            e.target.setAttribute('maxLength', 10)
        }
        if (/\D/g.test(value)) {
            value = value.replace(/\D/g, '');
        }

        if (value <= 0) {
            setUnitBet('');
        } else {
            setUnitBet(value);
        }
    };

    const getFbMinUnitBet = () => {
        // const minBet = parseInt( getPersonalSettings( 'football', betObj.betType),10 ) || 10;
        const minBet = window.fbConfig?.AllPoolsMinBet?.[betObj.betType] || 10;
        return minBet;
    };

    const onBlurUnitBet = () => {
        if (unitBet < getFbMinUnitBet() || unitBet > Number(GetChannelPara('MaxBetUnit'))) {
            setIsShowError(new Boolean(true));
            setUnitBet(betLineData.unitBet);
        } else {
            const newBetLineData = {
                ...betLineData,
                unitBet
            };

            updateBetLinespData(newBetLineData);
        }
    };

    const handeleDeleteBetLineData = () => {
        if (isShowPreviewInfo) {
            const flag = window.confirm(t('LB_BS_REMOVE_BETLINE_MSG1'));
            if (flag) {
                removeBetLinesItem(betLineData.id);
            }

            return;
        }
        removeBetLinesItem(betLineData.id);
        glassboxCustomEvent('Remove_Betline');
    };

    const handleClickAllUp = () => {
        if (isDim) return;
        setCheckedAllUp(!checkedAllUp);
        panelAllupCheckFn(betLineData);
    };

    const updateDeleteButton = (isChecked) => {
        if (isChecked === true) {
            return 'switch-btn-icon open-btn-icon disabled';
        } else {
            return 'switch-btn-icon open-btn-icon';
        }
    };

    const updateResendBetStatus = (val) => {
        const newBetLineData = {
            ...betLineData,
            ...val
        };
        updateResendBetLine(newBetLineData);
    };

    const handleClickResendBetBtn = (val) => {
        updateResendBetStatus({ isCounterOffer: val });
    };

    const combinationName = (comb) => {
        let selectedComb = comb.id.split('_')[comb.id.split('_').length - 1];
        let condition = !comb.condition || comb.condition === '0.0' ? '' : `[${comb.condition}]`;
        if (['HIL', 'FHL', 'CHL', 'EHL', 'ECH', 'FCH'].includes(comb.betType)) {
            selectedComb = selectedComb === 'H' ? 'HIGH' : 'LOW';
            condition = !comb.condition || comb.condition === '0.0' ? '' : comb.lineNum ? `[${comb.lineNum}]` : `[${comb.condition}]`;
        }
        let combName = t(`LB_BS_ADDTOSLIP_FB_${selectedComb}`);
        if (['TTG', 'ETG'].includes(comb.betType)) {
            combName = selectedComb;
        }
        if (['CRS', 'FCS', 'ECS'].includes(comb.betType)) {
            combName = isCRSOtherSels(selectedComb)
                ? t(`LB_BS_ADDTOSLIP_FB_${selectedComb}`)
                : selectedComb
                      .split(':')
                      .map((i) => parseInt(i))
                      .join(':');
        }
        if (['HFT'].includes(comb.betType)) {
            combName = selectedComb
                .split(':')
                .map((i) => t(`LB_BS_ADDTOSLIP_FB_${i}_S`))
                .join('-');
        }
        if (['FTS'].includes(comb.betType)) {
            combName = selectedComb
                .split(':')
                .map((i) => t(`LB_BS_ADDTOSLIP_FB_${i}_M`))
                .join('-');
        }
        if (['MSP', 'TSP', 'FGS', 'SGA'].includes(comb.betType)) {
            const nameObj = comb.currentCombNameObj || {};
            selectedComb = selectedComb === '000' ? '00' : selectedComb;
            combName = `${['FGS', 'SGA'].includes(comb.betType) ? `${selectedComb} ` : `(${selectedComb})`}${
                nameObj['name_' + i18n.language]
            }`;
        }
        if (['CHP', 'TPS', 'TQL', 'FINALIST', 'WINCNTY', 'WINCNTT'].includes(comb.betType)) {
            const selectedCombNumber = comb.currentCombNameObj?.str;
            combName = `${selectedCombNumber} ${comb?.currentCombNameObj?.['name_' + i18n.language]}`;
        }
        if (['GPW', 'GPF'].includes(comb.betType)) {
            combName = (
                <>
                    {comb?.currentPool?.['name_' + i18n.language]}
                    <br /> {comb?.currentCombNameObj?.['name_' + i18n.language]}
                </>
            );
        }
        return (
            <>
                {combName}
                {condition}
            </>
        );
    };

    const instNoName = (betObj, betType) => {
        switch (betType) {
            case 'TQL':
            case 'GPF':
            case 'GPW':
                return betObj?.combs?.[0]?.currentPool?.instNo;
            case 'NTS':
            case 'ENT':
                const instNo = betObj?.instNo;
                let ngoal = `${instNo}${i18n.language == 'en' ? getNumberSuffix(instNo) : ''}`;
                return `(${t('LB_FB_N_GOAL').replace('{0}', ngoal)})`;
            default:
                return '';
        }
    };

    const allupLabelFn = (isShort) => {
        let betList = betObj.betList;
        if (TournamentAllupPools.includes(betList[0].betType)) {
            betList = betList[0].combs;
        }
        const firstBetType = betList[0].betType;
        const isCrossPoolAllup = betList.find((item) => {
            return item.betType !== firstBetType;
        });
        const text = isShort
            ? isCrossPoolAllup
                ? 'LB_BS_BET_CROSSALLUP'
                : 'LB_BS_ALUP_S'
            : isCrossPoolAllup
            ? 'LB_RBC_ALUP'
            : 'LB_ALLUP';
        return t(text);
    };

    const getAlupDividend = () => {
        const noOfSelArr = [],
            maxOddsArr = [],
            newPanelAllupData = {
                alupFormula: betObj.selectedFormula,
                unitBet
            };

        const isTournamentAllupBet = TournamentAllupPools.includes(betObj?.betList?.[0].betType);
        let curBetList = [...betObj.betList];

        if (isTournamentAllupBet) {
            curBetList = betObj.betList[0].combs;
            //group bet list by poolId first
            const betListGroup = curBetList.reduce((group, comb) => {
                const { poolId } = comb;
                group[poolId] = group[poolId] ?? [];
                group[poolId].push(comb);
                return group;
            }, {});

            //then get largest odds for each group
            Object.values(betListGroup)?.forEach((betList) => {
                const maxOdds = Math.max.apply(
                    null,
                    betList.map((i) => Number(i.oddsVal))
                );
                maxOddsArr.push(maxOdds);
            });
        } else {
            let selsByMatch = curBetList.reduce((init, next) => {
                let key = next.matchId ;
                if (next.combs) {
                    next.combs.forEach((x) => {
                        let [tempOddsType, lineNum, combId, _, comb] = x.id.split('_');
                        init[key] = init[key] || [];
                        init[key].push({
                            type: tempOddsType,
                            line: x.lineNum,
                            combId: combId,
                            comb: comb,
                            odds: parseFloat(x.oddsVal)
                        });
                    });
                } else {
                    let [tempOddsType, lineNum, combId, _, comb] = next.id.split('_');
                    init[key] = init[key] || [];
                    init[key].push({
                        type: tempOddsType,
                        line: next.lineNum,
                        combId: combId,
                        comb: comb,
                        odds: parseFloat(next.oddsVal)
                    });
                }
                return init;
            }, {});

            for (let idx in selsByMatch) {
                if (selsByMatch.hasOwnProperty(idx)) {
                    selsByMatch[idx].sort((a,b) => {
                        if(!a.line || !b.line) return 0
                        let aLines = a.line.split('/');
                        let bLines = b.line.split('/');
                        if (parseFloat(aLines[0]) != parseFloat(bLines[0]))
                            return parseFloat(aLines[0]) - parseFloat(bLines[0]);
                        else if (aLines.length==1 && bLines.length > 1)
                            return -1;
                        else if (aLines.length>1 && bLines.length==1)
                            return 1;
                        return parseInt(a.combid) - parseInt(b.combid);
                    });
                    noOfSelArr.push(selsByMatch[idx].length);
                    var maxodds = Math.max(...selsByMatch[idx].map((x) => x.odds));
                    if (GoalLinePools.includes(selsByMatch[idx][0].type)) {
                        
                        maxodds = getLinePoolsMaxOdds(selsByMatch[idx]);

                    }
                    if (HandicapPools.includes(selsByMatch[idx][0].type)) {
                        maxodds = getHandicapPoolsMaxOdds(selsByMatch[idx]);
                    }
                    maxOddsArr.push(maxodds);
                }
            }
        }

        const alupDividend = fbCalMultiSel(noOfSelArr, maxOddsArr, newPanelAllupData);
        return alupDividend.dividend;
    };

    const maxDivFn = () => {
        let num = 10;
        if (betObj.betType == 'ALUP') {
            num = getAlupDividend();
        } else {
            let maxDiv = Number(betObj?.combs?.[0]?.oddsVal || 1);
            num = NumberOfBet * unitBet * maxDiv;
        }
        return textFormatAmountFn(num);
    };

    const betTypeName = (
        <>
            {t(`LB_FB_TITLE_${betObj.betType}`)} {instNoName(betObj, betObj.betType)}
        </>
    );

    let sendBetStatus = betLineData?.resultCode === 0 ? 'accepted' : !betLineData?.resultCode ? 'unknown' : 'rejected';
    if (betLineData?.resultCode === 0 && betLineData?.showResendControlBtn) {
        sendBetStatus = 'rejected';
    }
    const isUnknown = !betLineData.resultCode && betLineData.resultCode != 0;
    const ignoreClassList = ['unitbet-input', 'switch-btn-icon', 'betline-checkbox'];

    const AllUpCheckBox = useCallback(({betLineData, handleClickAllUp, checkedAllUp, t}) => {
        return (
            <>
                {betLineData.showAllUpCheckBox && (
                    <div className="betline-checkbox df" onClick={handleClickAllUp}>
                        <div>
                            <CheckBox product="football" checked={checkedAllUp} />
                        </div>
                        <div>{t('LB_ALLUP')}</div>
                    </div>
                )}
            </>
        );
    },[]);

    const disabled = (panelAllupObj?.uniqueMatchIdList?.length >= 1 && betObj.betType == 'ALUP') || isDim;
    const unitBetStr = textFormatAmountFn(unitBet, 0, false);
    const allupCheckBoxProps = {betLineData, handleClickAllUp, checkedAllUp, t}
    const betLineUnavailable = betObj.isStopSell

    return (
        <>
            {isExpended ? (
                <div
                    className={`bet-line fb-bet-line ${betLineUnavailable ? 'betLine-unavailable' : ''}`}
                    onClick={(e) => handleClickExpendBox(e, ignoreClassList)}
                >
                    <BetLineConfirmationHeader
                        isShow={isShowConfirmation}
                        status={sendBetStatus}
                        code={betLineData.resultCode}
                        txNo={betLineData.txNo}
                        isCounterOffer={betLineData?.showResendControlBtn}
                        resultMsg={betLineData.resultMsg}
                        resultMsgChi={betLineData.resultMsgChi}
                    />
                    {betObj.betType !== 'ALUP' ? (
                        <>
                            <div className="title">
                                <div>
                                    {betObj.frontEndId} | {tournamentName}
                                </div>
                                {!isShowConfirmation && !isShowPreviewInfo && (
                                    <div
                                        onClick={handeleDeleteBetLineData}
                                        className={`${updateDeleteButton(checkedAllUp)} ${betLineUnavailable ? 'z-index3' : ''}`}
                                    ></div>
                                )}
                            </div>
                            {isShowPreviewInfo ? (
                                <CounterOfferTemplateContent
                                    matchTeamName={matchTeamName}
                                    betLineData={betLineData}
                                    specialsLine={specialsLine}
                                    combinationName={combinationName}
                                    handleClickResendBetBtn={handleClickResendBetBtn}
                                    isResendBetLine={isResendBetLine}
                                    unitBet={unitBet}
                                    maxDivFn={maxDivFn}
                                />
                            ) : (
                                <div className="content">
                                    <div className="betLineTeamName">{matchTeamName}</div>
                                    {specialsLine && (
                                        <div className="mult-item-name">
                                            {' '}
                                            {specialsLine.instNo}{' '}
                                            {i18n.language === 'en' ? specialsLine.name_en : specialsLine.name_ch}
                                        </div>
                                    )}
                                    {betObj.combs.map((comb, index) => {
                                        const combName = combinationName(comb);
                                        const oddsChangeCss = getOddsArrowCss(comb);
                                        const showOdds = <div key={comb.id} className={`comb_${index}`}>
                                        {combName}@{['ELIMINATED', 'REFUND'].includes(comb.sellStatus) ? t(`LB_BS_SELLSTATE_${comb?.sellStatus?.toUpperCase()}`) : comb.showDash ? '---' : <span className={`betline-odds ${oddsChangeCss}`}><span className='odds-value'>{comb.oddsVal}</span>{oddsChangeCss && <span className='oddsArrow'>▲</span>}</span>}
                                    </div>
                                        if(index === 0 && betLineUnavailable ) {
                                            return <div className='flex-between'>
                                                {showOdds}
                                                <div className={`betLine-unavailable-text ${betLineUnavailable ? 'z-index3' : ''}`}>{t(`LB_BS_SELLSTATE_${betObj?.sellStatus?.toUpperCase()}`)}</div>
                                            </div>
                                        }
                                        return showOdds;
                                    })}
                                    {isShowPreviewInfo && (
                                        <div className="fb-preview-bet">
                                            ${isUnknown && isShowConfirmation ? '-' : textFormatAmountFn(unitBet)}
                                        </div>
                                    )}
                                </div>
                            )}
                            {isShowPreviewInfo ? (
                                <div>
                                    <div className="fb-preview-bettype">{betTypeName}</div>
                                    <div className="fb-preview-amount">
                                        <span>{t('LB_RBC_AMOUNT')} :</span>
                                        <span className="font15">
                                            $
                                            {isUnknown && isShowConfirmation
                                                ? '-'
                                                : isShowConfirmation 
                                                ? textFormatAmountFn(betLineData.resultAmount)
                                                : textFormatAmountFn(NumberOfBet * unitBet)}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="bet-type-col">
                                        <div className="betTypeName">{betTypeName}</div>
                                        <div className={`unitbet-input ${checkedAllUp ? 'opacity25' : ''}`}>
                                            <span>$</span>
                                            <input
                                                maxLength="10"
                                                type="text"
                                                inputMode="numeric"
                                                style={{ fontSize: flexiFontSize(unitBetStr) }}
                                                value={unitBetStr}
                                                onFocus={onFocusBetTextbox}
                                                onInput={onInputUnitBet}
                                                onBlur={onBlurUnitBet}
                                                disabled={checkedAllUp}
                                            />
                                        </div>
                                    </div>
                                    <div className="maxDiv-col">
                                        <div className={`right ${checkedAllUp ? 'opacity25' : ''}`}>
                                            <span>{t('LB_FB_MAXIMUM_DIVIDEND')}:</span>
                                            <div className={`${!betLineUnavailable && maxDivFn()?.length > 12 ? 'dividend12px' : ''}`}>
                                                ${betLineUnavailable ? '---' : maxDivFn()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`amount-col`}>
                                        <div className="left">
                                            <div>
                                                <div className={betLineData.showAllUpCheckBox ? 'noOfBets35px' : ''}>
                                                    {t('LB_FB_NO_OF_BET')}:
                                                </div>{' '}
                                                <span>{NumberOfBet}</span>
                                            </div>
                                            <AllUpCheckBox {...allupCheckBoxProps}/>
                                        </div>
                                        <div className={`right ${checkedAllUp ? 'opacity25' : ''}`}>
                                            <span>{t('LB_BS_BET_TOTAL')}:</span>
                                            <div
                                                className={`${
                                                    !betLineUnavailable && textFormatAmountFn(NumberOfBet * unitBet)?.length > 12
                                                        ? 'dividend12px'
                                                        : ''
                                                }`}
                                            >
                                                ${betLineUnavailable ? '---' :textFormatAmountFn(NumberOfBet * unitBet)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="title">
                                <div>{t('LB_FB_BET_ALLUP')}</div>
                                {!isShowPreviewInfo && !isShowConfirmation && (
                                    <div
                                        onClick={handeleDeleteBetLineData}
                                        className={`${updateDeleteButton(checkedAllUp)} ${betLineUnavailable ? 'z-index3' : ''}`}
                                    ></div>
                                )}
                            </div>
                            {isShowPreviewInfo ? (
                                <CounterOfferALUPTemplate
                                    matchTeamName={matchTeamName}
                                    betLineData={betLineData}
                                    isShowConfirmation={isShowConfirmation}
                                    specialsLine={specialsLine}
                                    combinationName={combinationName}
                                    handleClickResendBetBtn={handleClickResendBetBtn}
                                    unitBet={unitBet}
                                    maxDivFn={maxDivFn}
                                    TournamentAllupPools={TournamentAllupPools}
                                    TournAllUpTemp={TournAllUpTemp}
                                    isResendBetLine={isResendBetLine}
                                    isShowPreviewInfo={isShowPreviewInfo}
                                />
                            ) : (
                                <div className="content content-calcAllup">
                                    {betObj.betList.map((item, idx) => {
                                        const lang = i18n.language === 'en' ? 'EN' : 'CH';
                                        if (TournamentAllupPools.includes(item.betType)) {
                                            return (
                                                <TournAllUpTemp
                                                    betObj={betObj}
                                                    objData={item}
                                                    key={item.matchId}
                                                    combinationName={combinationName}
                                                    isShowPreviewInfo={isShowPreviewInfo}
                                                />
                                            );
                                        } else {
                                            let goalStr = instNoName(item, item.betType);
                                            return (
                                                <div key={item.matchId}>
                                                    <div className="subText subTitle">
                                                        {item.frontEndId} | {item['tournamentName' + lang]}
                                                        {idx === 0 && betLineUnavailable && <div className={`betLine-unavailable-text ${betLineUnavailable ? 'z-index3' : ''}`}>{t(`LB_BS_SELLSTATE_${betObj?.sellStatus?.toUpperCase()}`)}</div>}
                                                    </div>
                                                    <div>{item['matchTeam' + lang]}</div>
                                                    <div className="subText">
                                                        {t('LB_FB_TITLE_' + item.betType)} {goalStr}
                                                    </div>
                                                    <div className="">
                                                        {item.combs.map((comb, index) => {
                                                            const combName = combinationName(comb);
                                                            const oddsChangeCss = getOddsArrowCss(comb);
                                                            return (
                                                                <div key={comb.id} className={`comb_${index}`}>
                                                                    {combName}@{['ELIMINATED', 'REFUND'].includes(comb.sellStatus) ? t(`LB_BS_SELLSTATE_${comb?.sellStatus?.toUpperCase()}`) : comb.showDash ? '---' : <span className={`betline-odds ${oddsChangeCss}`}><span className='odds-value'>{comb.oddsVal}</span>{oddsChangeCss && <span className='oddsArrow'>▲</span>}</span>}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                    {isShowPreviewInfo && (
                                        <div className="fb-preview-bet">${textFormatAmountFn(unitBet)}</div>
                                    )}
                                </div>
                            )}
                            {isShowPreviewInfo ? (
                                <div>
                                    <div className="fb-preview-bettype">
                                        {allupLabelFn()} {betObj.selectedFormula.replace('x', t('LB_FB_CAL_CROSS'))}
                                    </div>
                                    <div className="fb-preview-amount">
                                        <span>{t('LB_RBC_AMOUNT')} :</span>
                                        <span className="font15">
                                            $
                                            {isUnknown && isShowConfirmation
                                                ? '-'
                                                : isShowConfirmation 
                                                ? textFormatAmountFn(betLineData.resultAmount)
                                                : textFormatAmountFn(NumberOfBet * unitBet)}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="bet-type-col">
                                        <div>
                                            {allupLabelFn()}{' '}
                                            {betObj?.selectedFormula?.replace('x', t('LB_FB_CAL_CROSS'))}
                                        </div>
                                        <div className={`unitbet-input `}>
                                            <span>$</span>
                                            <input
                                                maxLength="10"
                                                type="text"
                                                inputMode="numeric"
                                                style={{ fontSize: flexiFontSize(unitBetStr) }}
                                                value={unitBetStr}
                                                onInput={onInputUnitBet}
                                                onBlur={onBlurUnitBet}
                                                onFocus={onFocusBetTextbox}
                                            />
                                        </div>
                                    </div>
                                    <div className="maxDiv-col">
                                        <div className="right">
                                            <span>{t('LB_FB_MAXIMUM_DIVIDEND')}:</span>
                                            <div className={`${!betLineUnavailable && maxDivFn()?.length > 12 ? 'dividend12px' : ''}`}>
                                                ${betLineUnavailable ? '---' : maxDivFn()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="amount-col amount-col-calcAllup">
                                        <div className="left">
                                            <div>
                                                <div>{t('LB_RC_NO_OF_BETS')}:</div> <span>{betObj.noOfComb}</span>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <span>{t('LB_BS_BET_TOTAL')}:</span>
                                            <div
                                                className={`${
                                                    !betLineUnavailable && textFormatAmountFn(betObj.noOfComb * unitBet)?.length > 12
                                                        ? 'dividend12px'
                                                        : ''
                                                }`}
                                            >
                                                ${betLineUnavailable ? '---' : textFormatAmountFn(betObj.noOfComb * unitBet)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {(disabled || betLineUnavailable) && <div className="alup-dim"></div>}
                </div>
            ) : (
                <CollapseBetLine
                    unitBet={unitBet}
                    betObj={betObj}
                    ignoreClassList={ignoreClassList}
                    handeleDeleteBetLineData={handeleDeleteBetLineData}
                    updateDeleteButton={updateDeleteButton}
                    checkedAllUp={checkedAllUp}
                    onFocusBetTextbox={onFocusBetTextbox}
                    onInputUnitBet={onInputUnitBet}
                    onBlurUnitBet={onBlurUnitBet}
                    specialsLine={specialsLine}
                    panelAllupObj={panelAllupObj}
                    isDim={isDim}
                    handleClickExpendBox={handleClickExpendBox}
                    allupLabelFn={allupLabelFn}
                    instNoName={instNoName}
                    AllUpCheckBox={AllUpCheckBox}
                    allupCheckBoxProps={allupCheckBoxProps}
                    onClickBetTextbox={onClickBetTextbox}
                />
            )}
        </>
    );
};

export default FBBetLineTemplate;

const CollapseBetLine = (props) => {
    const { t, i18n } = useTranslation();
    const {
        unitBet,
        betObj,
        panelAllupObj,
        specialsLine,
        ignoreClassList,
        handeleDeleteBetLineData,
        updateDeleteButton,
        AllUpCheckBox,
        allupCheckBoxProps,
        isDim,
        handleClickExpendBox,
        allupLabelFn,
        instNoName,
        checkedAllUp,
        onFocusBetTextbox,
        onInputUnitBet,
        onBlurUnitBet,
        onClickBetTextbox
    } = props;
    const betLineUnavailable = betObj?.isStopSell
    const shortCombinationName = (comb) => {
        let selectedComb = comb.id.split('_')[comb.id.split('_').length - 1];
        let condition = !comb.condition || comb.condition === '0.0' ? '' : `[${comb.condition}]`;
        if (['HIL', 'FHL', 'CHL', 'EHL', 'ECH', "FCH"].includes(comb.betType)) {
            selectedComb = selectedComb === 'H' ? 'HIGH' : 'LOW';
        }
        let combName = t(`LB_BS_ADDTOSLIP_FB_${selectedComb}`);
        if (['HAD', 'EHA', 'FHA', 'FTS', 'NTS', 'ENT', ...window.fbConfig.HandicapPools].includes(comb.betType)) {
            combName = t(`LB_BS_ADDTOSLIP_FB_${selectedComb}_S`);
        }
        if (['TTG', 'ETG'].includes(comb.betType)) {
            combName = `${selectedComb}`;
        }
        if (['CRS', 'FCS', 'ECS'].includes(comb.betType)) {
            combName = isCRSOtherSels(selectedComb)
                ? t(`LB_BS_ADDTOSLIP_FB_${selectedComb}`)
                : selectedComb
                      .split(':')
                      .map((i) => parseInt(i))
                      .join(':');
        }
        if (['HFT'].includes(comb.betType)) {
            combName = selectedComb
                .split(':')
                .map((i) => t(`LB_BS_ADDTOSLIP_FB_${i}_S`))
                .join('-');
        }
        if (['MSP', 'TSP', 'FGS'].includes(comb.betType)) {
            const nameObj = comb.currentCombNameObj || {};
            selectedComb = selectedComb === '000' ? '00' : selectedComb;
            combName = `${['FGS', 'TSP'].includes(comb.betType) ? `${selectedComb} ` : `(${selectedComb})`}${
                nameObj['name_' + i18n.language]
            }`;
        }
        if (['SGA'].includes(comb.betType)) {
            const nameObj = comb.currentCombNameObj || {};
            selectedComb = selectedComb === '000' ? '00' : selectedComb;
            combName = `${selectedComb}. ${nameObj['name_' + i18n.language]}`;
        }
        if (['TPS', 'FINALIST', 'WINCNTY', 'WINCNTT'].includes(comb.betType)) {
            const selectedCombNumber = comb.currentCombNameObj.str;
            combName = `${selectedCombNumber}`;
        }
        if (['CHP', 'TQL'].includes(comb.betType)) {
            const selectedCombNumber = comb.currentCombNameObj.str;
            let combNameObjTrim = comb.currentCombNameObj['name_' + i18n.language];
            if (combNameObjTrim.length > 3) {
                combNameObjTrim = combNameObjTrim.slice(0, 3) + '...';
            }
            combName = `${selectedCombNumber} ${combNameObjTrim}`;
        }
        if (['GPW', 'GPF'].includes(comb.betType)) {
            combName = comb?.currentComb?.selections?.map((i) => i.str).join('-');
            if ('GPF' === comb.betType) {
                combName = `${comb?.comb[1]}-${comb?.comb[4]}`;
            }
        }

        const oddsChangeCss = getOddsArrowCss(comb);
        const isALUP = betObj.betType.includes('ALUP');
        let showSpecialOdds = ['ELIMINATED', 'REFUND'].includes(comb.sellStatus)
        return `<span class='betline-odds ${isALUP && oddsChangeCss && !comb.showDash ? "allup-odds-change" : oddsChangeCss}  ${!isALUP ? "odds-change" : ""}  ${showSpecialOdds ? 'specialOdds' : ''}'><span class='title-text'>${combName}${condition}</span>@${showSpecialOdds ? t(`LB_BS_SELLSTATE_${comb?.sellStatus?.toUpperCase()}`) : comb.showDash ? '---' : `<span class='odds-value'>${comb.oddsVal}</span><span class='oddsArrow'>▲</span>`}</span>`;
    };
    const tournCode = (betType) => {
        if (['CHP', 'TPS', 'TSP', 'TQL', 'FINALIST', 'WINCNTY', 'WINCNTT', 'GPW', 'GPF'].includes(betType)) {
            return ' ' + betObj?.tournamentCode;
        }
        return '';
    };

    const tournmentCode = tournCode(betObj.betType);
    let betName = t(`LB_BS_${betObj.betType}_S`);
    let betLineStr = '';
    let instNo = ['TSP', 'MSP'].includes(betObj.betType) ? specialsLine.instNo : instNoName(betObj, betObj.betType);
    if (betObj.betType.includes('ALUP')) {
        betName = `${allupLabelFn(true)} ${betObj?.selectedFormula || ''}`;
        betLineStr = betObj.betList.map((item) => {
            const itemBetName = t(`LB_BS_${item.betType}_S`);
            let str = `${item.frontEndId} ${itemBetName}`;
            item.combs.forEach((i) => {
                str += ' ' + shortCombinationName(i);
            });
            return str;
        });
    } else {
        betName = `${betObj.frontEndId} | ${tournmentCode} ${betName} ${instNo}`;
        betLineStr = betObj?.combs?.map((i) => shortCombinationName(i));
    }

    const disabled = (panelAllupObj?.uniqueMatchIdList?.length >= 1 && betObj.betType == 'ALUP') || isDim;
    const unitBetStr = textFormatAmountFn(unitBet, 0, false);
    const oddsChanged = betObj.betType.includes('ALUP') && betLineStr?.some((i) => {
        return i.includes('allup-odds-change')
    })
    const hasEliminated = betLineStr?.some((i) => {
        return i.includes('Eliminated')
    })
    return (
        <div
            className={`bet-line fb-bet-line bet-line-collapse ${betLineUnavailable ? 'betLine-unavailable' : ''}`}
            onClick={(e) => handleClickExpendBox(e, ignoreClassList)}
        >
            <div className="title">
                <div>{betName}</div>
                {oddsChanged && <div className='odds-changed'>{t('LB_BS_ODDS_CHANGED')}</div>}
                <div onClick={handeleDeleteBetLineData} className={`${updateDeleteButton(checkedAllUp)} ${betLineUnavailable ? 'z-index3' : ''}`}></div>
            </div>
            <div className={`bet-type-col `}>
                <div className="collapse-content-l">
                    <AllUpCheckBox {...allupCheckBoxProps}/>
                    <div className={`collapse-betline`}  dangerouslySetInnerHTML={{ __html: betLineStr }}></div>
                </div>
                <div className={`collapse-content-r ${betLineUnavailable ? 'z-index3' : ''}`}>
                    {!betLineUnavailable && <div className={`unitbet-input ${checkedAllUp ? 'opacity25' : ''}`}>
                        <span>$</span>
                        <input
                            maxLength="10"
                            type="text"
                            inputMode="numeric"
                            style={{ fontSize: flexiFontSize(unitBetStr) }}
                            value={unitBetStr}
                            onFocus={onFocusBetTextbox}
                            onClick={onClickBetTextbox}
                            onInput={onInputUnitBet}
                            onBlur={onBlurUnitBet}
                            disabled={checkedAllUp}
                        />
                    </div>}
                    {betLineUnavailable && <div className={`betLine-unavailable-text ${hasEliminated ? 'hasEliminated' : ''}`}>{t(`LB_BS_SELLSTATE_${betObj?.sellStatus?.toUpperCase()}`)}</div>}
                </div>
            </div>
            {(disabled || betLineUnavailable) && <div className="alup-dim"></div>}

        </div>
    );
};

const TournAllUpTemp = ({ objData, combinationName, isShowPreviewInfo, betObj }) => {
    const { i18n, t } = useTranslation();
    const langU = i18n.language === 'en' ? 'EN' : 'CH';
    const langL = i18n.language === 'en' ? 'en' : 'ch';
    let groupObJ = {};
    let groupArr = [];
    objData.combs.forEach((item) => {
        const objKey = item.raceno;
        if (groupObJ[objKey]) {
            groupObJ[objKey].push(item);
        } else {
            groupObJ[objKey] = [item];
        }
    });
    groupArr = Object.keys(groupObJ).sort((a, b) => a[0].raceno - b[0].raceno);
    const betLineUnavailable = betObj?.isStopSell
    return (
        <div>
            <div className="subText subTitle">
                {objData.frontEndId} | {objData['tournamentName' + langU]}
                {betLineUnavailable && <div className={`betLine-unavailable-text ${betLineUnavailable ? 'z-index3' : ''}`}>{t(`LB_BS_SELLSTATE_${betObj?.sellStatus?.toUpperCase()}`)}</div>}
            </div>
            {groupArr.map((key) => {
                let betArr = groupObJ[key];
                return betArr.map((item, index) => {
                    const combName = combinationName(item);
                    const oddsChangeCss = isShowPreviewInfo ? '' : getOddsArrowCss(item);
                    return (
                        <div key={index}>
                            {index === 0 && (
                                <div className="subText">
                                    {t('LB_FB_TITLE_' + item.betType)} {item?.currentPool['instNo']}{' '}
                                    {item?.currentPool['name_' + langL]}
                                </div>
                            )}
                            <div>
                                {item?.currentCombNameObj['name_' + langL]}@{['ELIMINATED', 'REFUND'].includes(item.sellStatus) ? t(`LB_BS_SELLSTATE_${item?.sellStatus?.toUpperCase()}`) : item.showDash ? "---" : <span className={`betline-odds ${oddsChangeCss}`}><span className='odds-value'>{item.oddsVal}</span>{oddsChangeCss && <span className='oddsArrow'>▲</span>}</span>}
                            </div>
                        </div>
                    );
                });
            })}
        </div>
    );
};

const CounterOfferTemplateContent = ({
    matchTeamName,
    isResendBetLine,
    betLineData,
    specialsLine,
    combinationName,
    handleClickResendBetBtn,
    unitBet,
    maxDivFn
}) => {
    const { t, i18n } = useTranslation();
    const betObj = betLineData.betObj;
    const isAutoAcceptance = betLineData.isAutoAcceptance;
    const showResendControlBtn = betLineData?.showResendControlBtn;
    const counterOfferOddsChange = betLineData?.counterOfferOddsChange;
    const counterOfferAmountChange = betLineData?.counterOfferAmountChange;
    const oldUnitBet = betLineData.oldUnitBet;
    const amountUp = Number(unitBet) > Number(oldUnitBet);
    const amountChanged = oldUnitBet != undefined && unitBet != oldUnitBet;
    let resendMsg = counterOfferAmountChange ? t('LB_BS_RESENDBET_AMOUNT_CHANGED') : t('LB_BS_RESENDBET_ODDS_CHANGED');
    if (counterOfferOddsChange && counterOfferAmountChange) {
        resendMsg = t('LB_BS_RESENDBET_AMOUNTANDODDS_CHANGED');
    }

    return (
        <>
            <div className="content COContent">
                <div className="betLineContentTop">
                    <div className="left">
                        <div className="betLineTeamName">{matchTeamName}</div>
                        {specialsLine && (
                            <div className="mult-item-name">
                                {' '}
                                {specialsLine.instNo}{' '}
                                {i18n.language === 'en' ? specialsLine.name_en : specialsLine.name_ch}
                            </div>
                        )}
                        {betObj.combs.map((comb, index) => {
                            const combName = combinationName(comb);
                            const newOdds = comb.oddsVal;
                            const oldOdds = comb.oldOddsVal;
                            const oddsUp = Number(newOdds) > Number(oldOdds);
                            const oddsChanged = oldOdds != undefined && newOdds != oldOdds;

                            // console.log(
                            //     'comb',
                            //     comb,
                            //     'newOdds:' + newOdds,
                            //     'oldOdds:' + oldOdds,
                            //     'oddsUp:' + oddsUp,
                            //     'oddsChange:' + oddsChanged
                            // );

                            let oddsContent = '';
                            if (oddsChanged && (isAutoAcceptance || counterOfferOddsChange)) {
                                oddsContent = (
                                    <>
                                        {oldOdds} {t('LB_BS_RESENDBET_CHANGED_TO')}{' '}
                                        <span className={`comb_newodds ${oddsUp ? 'oddsUp' : 'oddsDown'}`}>
                                            {newOdds}
                                            <span></span>
                                        </span>
                                    </>
                                );
                            } else {
                                oddsContent = newOdds;
                            }

                            return (
                                <div key={comb.id} className={`comb_${index}`}>
                                    {combName}@{oddsContent}
                                </div>
                            );
                        })}
                    </div>
                    <div className="right">
                        {counterOfferAmountChange && isResendBetLine && amountChanged ? (
                            <>
                                <div className={`coAmountChangeText  ${
                                    textFormatAmountFn(oldUnitBet)?.length > 8 ? 'dividend10px' : ''
                                }`}>
                                    ${textFormatAmountFn(oldUnitBet)}
                                    <wbr /> {t('LB_BS_RESENDBET_CHANGED_TO')}
                                </div>
                                <div
                                    className={`fb-preview-bet ${
                                        textFormatAmountFn(unitBet)?.length > 11 ? 'dividend10px' : ''
                                    } ${amountUp ? 'amountUp' : 'amountDown'}`}
                                >
                                    <span className={`${amountUp ? 'comb_newodds oddsUp' : 'comb_newodds oddsDown'}`}>
                                        ${textFormatAmountFn(unitBet)}
                                        <span></span>
                                    </span>
                                </div>
                            </>
                        ) : (
                            <div className={`coAmountChangeText  ${
                                textFormatAmountFn(unitBet)?.length > 8 ? 'dividend10px' : ''
                            }`}>
                                <div className={textFormatAmountFn(unitBet)?.length > 11 ? 'dividend10px' : ''}>${textFormatAmountFn(unitBet)}</div>
                                <span></span>
                            </div>
                        )}
                        <div className="fb-preview-amount">
                            <span>{t('LB_FB_MAXIMUM_DIVIDEND')} :</span>
                            <div className={maxDivFn()?.length < 12 ? 'font15' : 'dividend10px'}>${maxDivFn()}</div>
                        </div>
                    </div>
                </div>
                {showResendControlBtn && isResendBetLine && (
                    <div className="resendBetBtnContent">
                        <div>{resendMsg}</div>
                        <div>
                            <div
                                className={betLineData.isCounterOffer ? '' : 'active'}
                                onClick={() => handleClickResendBetBtn(false)}
                            >
                                {t('LB_BS_RESENDBET_REJECT')}
                            </div>
                            <div
                                className={betLineData.isCounterOffer ? 'active' : ''}
                                onClick={() => handleClickResendBetBtn(true)}
                            >
                                {t('LB_BS_RESENDBET_ACCEPT')}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

const CounterOfferALUPTemplate = ({
    betLineData,
    combinationName,
    handleClickResendBetBtn,
    unitBet,
    TournamentAllupPools,
    maxDivFn,
    TournAllUpTemp,
    isResendBetLine,
    isShowPreviewInfo
}) => {
    const { t, i18n } = useTranslation();
    const betObj = betLineData.betObj;
    const isAutoAcceptance = betLineData.isAutoAcceptance;
    const NumberOfBet = betObj?.noOfComb || 1;
    const showResendControlBtn = betLineData?.showResendControlBtn;
    const counterOfferOddsChange = betLineData?.counterOfferOddsChange;
    const counterOfferAmountChange = betLineData?.counterOfferAmountChange;
    const oldUnitBet = betLineData.oldUnitBet;
    const amountUp = Number(unitBet) > Number(oldUnitBet);
    const amountChanged = oldUnitBet != undefined && unitBet != oldUnitBet;

    const instNoName = (betObj, betType) => {
        switch (betType) {
            case 'NTS':
            case 'ENT':
                const instNo = betObj?.instNo;
                let ngoal = `${instNo}${i18n.language == 'en' ? getNumberSuffix(instNo) : ''}`;
                return `(${t('LB_FB_N_GOAL').replace('{0}', ngoal)})`;
            default:
                return '';
        }
    };

    let resendMsg = counterOfferAmountChange ? t('LB_BS_RESENDBET_AMOUNT_CHANGED') : t('LB_BS_RESENDBET_ODDS_CHANGED'); //wip bugfix
    if (counterOfferOddsChange && counterOfferAmountChange) {
        resendMsg = t('LB_BS_RESENDBET_AMOUNTANDODDS_CHANGED');
    }

    return (
        <div className="content content-calcAllup COContent">
            <div className="betLineContentTop">
                <div className="left">
                    {betObj.betList.map((item) => {
                        const lang = i18n.language === 'en' ? 'EN' : 'CH';
                        if (TournamentAllupPools.includes(item.betType)) {
                            return (
                                <TournAllUpTemp objData={item} key={item.matchId} combinationName={combinationName} isShowPreviewInfo={isShowPreviewInfo} betObj={betObj}/>
                            );
                        } else {
                            return (
                                <div key={item.matchId}>
                                    <div className="subText subTitle">
                                        {item.frontEndId} | {item['tournamentName' + lang]}
                                    </div>
                                    <div>{item['matchTeam' + lang]}</div>
                                    <div className="subText">
                                        {t('LB_FB_TITLE_' + item.betType)} {instNoName(item, item.betType)}
                                    </div>
                                    <div className="">
                                        {item.combs.map((comb, index) => {
                                            const combName = combinationName(comb);
                                            const newOdds = comb.oddsVal;
                                            const oldOdds = comb.oldOddsVal;
                                            const oddsUp = Number(newOdds) > Number(oldOdds);
                                            const oddsChanged = oldOdds != undefined && newOdds != oldOdds;

                                            // console.log(
                                            //     'comb',
                                            //     comb,
                                            //     'newOdds:' + newOdds,
                                            //     'oldOdds:' + oldOdds,
                                            //     'oddsUp:' + oddsUp,
                                            //     'oddsChange:' + oddsChanged
                                            // );

                                            let oddsContent = '';
                                            if (oddsChanged && (isAutoAcceptance || counterOfferOddsChange)) {
                                                oddsContent = (
                                                    <>
                                                        {oldOdds} {t('LB_BS_RESENDBET_CHANGED_TO')}{' '}
                                                        <span
                                                            className={`comb_newodds ${oddsUp ? 'oddsUp' : 'oddsDown'}`}
                                                        >
                                                            {newOdds}
                                                            <span></span>
                                                        </span>
                                                    </>
                                                );
                                            } else {
                                                oddsContent = newOdds;
                                            }

                                            return (
                                                <div key={comb.id} className={`comb_${index}`}>
                                                    {combName}@{oddsContent}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
                <div className="right">
                    {counterOfferAmountChange && isResendBetLine && amountChanged ? (
                        <>
                            <div className={`coAmountChangeText  ${
                                textFormatAmountFn(oldUnitBet)?.length > 8 ? 'dividend10px' : ''
                            }`}>
                                ${textFormatAmountFn(oldUnitBet)}
                                <wbr /> {t('LB_BS_RESENDBET_CHANGED_TO')}
                            </div>
                            <div
                                className={`fb-preview-bet ${
                                    textFormatAmountFn(unitBet)?.length > 11 ? 'dividend10px' : ''
                                } ${amountUp ? 'amountUp' : 'amountDown'}`}
                            >
                                <span className={`${amountUp ? 'comb_newodds oddsUp' : 'comb_newodds oddsDown'}`}>
                                    ${textFormatAmountFn(unitBet)}
                                    <span></span>
                                </span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={textFormatAmountFn(unitBet)?.length > 11 ? 'dividend10px' : ''}>${textFormatAmountFn(unitBet)}</div>
                            <span></span>
                        </>
                    )}
                    <div className="fb-preview-amount">
                        <span>{t('LB_FB_MAXIMUM_DIVIDEND')} :</span>
                        <div className={maxDivFn()?.length > 11 ? 'dividend10px' : 'font15'}>${maxDivFn()}</div>
                    </div>
                </div>
            </div>
            {showResendControlBtn && isResendBetLine && (
                <div className="resendBetBtnContent">
                    <div>{resendMsg}</div>
                    <div>
                        <div
                            className={betLineData.isCounterOffer ? '' : 'active'}
                            onClick={() => handleClickResendBetBtn(false)}
                        >
                            {t('LB_BS_RESENDBET_REJECT')}
                        </div>
                        <div
                            className={betLineData.isCounterOffer ? 'active' : ''}
                            onClick={() => handleClickResendBetBtn(true)}
                        >
                            {t('LB_BS_RESENDBET_ACCEPT')}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

