import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useForceUpdate from '../../Common/hooks/useForceUpdate';
import { getESMatchQuery, getQueryVariables } from '../../Football/Data/DataQuery';
import { getSetSequenceNumber } from '../../BetSlip/BetSlipIBAPI';
import { getBalanceValue } from '../../BetSlip/BetSlipHooks';
import { consoleLog, isEoD, textFormatAmountFn } from '../../Common/GlobalFunc';
import { parseMessage } from '../../Football/Data/useFootballPush/parseMessage';
import useBroadcastChannel from '../../Common/hooks/useBroadcastChannel';
import { Query, abortFBMatch, apiErrorCallback } from '../../Common/graphqlFetch'
import { isSettledLeg, isStopSellLeg, isMatchVoided, isAllLegSettled, anyWinComb, isLosingCombAll, isIrrationalAll, isOfferAll, isAnyPendingForResult, formatBetType } from '../../Football/Common/MatchBase';
import { uniqueList } from "../../Home/CashOut/CashOutBase";

export const useEarlySettlementTxnQuery = ({mqttClient, globalState}) => {
    const { t, i18n } = useTranslation();
    const forceUpdate = useForceUpdate();
    const [esLoading, setEsLoading] = useState(false)
    const esContentRef = useRef({ matchData : { matches: [] } });
    const dataRef = useRef([]);
    const esValues = useRef([]);
    const esStates = useRef([]);
    const esSettleds = useRef([]);
    const esPendingForResults = useRef([]);
    const submittedEsBets = useRef([]);
    const esDataRequestLoading = useRef(false);
    const [esToastMsgs, setEsToastMsgs] = useState([]);
    const [esErrorMsg, setEsErrorMsg] = useState('');
    const [esApiError, setEsApiError] = useState(false);
    const ssoLogoutBoardcast = useBroadcastChannel('ssoLogoutNow');
    const refreshBalance = useBroadcastChannel('refreshBalance');
    const { replayMsgQueue } = mqttClient;
    // const { query } = useApolloClient();
    const loadMatchByFrontEndIDs = (paras, pushData) => {
        setEsLoading(true)
        return Promise.all([Query({ 
            query : getESMatchQuery(),
            variables: getQueryVariables(paras)
        })])
        .then(([results]) => {
            if (!results && !results.data) return;
            esContentRef.current.matchData = results.data;
            filterFBDataInner(pushData);
            replayMsgCache();
        });
    }

    const getEsData = () => {
        return dataRef.current;
    }

    const replayMsgCache = () => {
        replayMsgQueue.current.forEach(({topic, message}) => {
            if ( topic.indexOf("early_stlmt")<0 ) {
                parseMessage(topic, message, esContentRef, [...replayMsgQueue.current], {isReplay:true, esReplay: true});
            }
        });
    }

    const getOpenBetsRequest = async (continueId, firstLoad, pushData) => {
        if ( submittedEsBets.current.length > 0 ) {
            return;
        }

        if (isEoD()) {
            ssoLogoutBoardcast?.postMessage('ssoLogoutNow');
            return;
        }

        consoleLog(`getOpenBet ${continueId}`);
        const url = `${window.globalConfig.LOGIN_SERVICE_URL}/services/EarlySettle.svc/GetOpenBets`;

        const payload = {
            lang: i18n.language,
            acc: sessionStorage.getItem('account'),
            seqNo: getSetSequenceNumber(),
            knownWebID: sessionStorage.getItem('sso_web_id'),
            knownSSOGUID: sessionStorage.getItem('sso_guid'),
            guid: sessionStorage.getItem('gu_id'),
            sid: sessionStorage.getItem('session_id'),
            continueId: continueId,
            isNew: true
        };

        return await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: JSON.stringify(payload)
        })
            .then((res) => {
                return res.json();
            })
            .then( async(data) => {
                setEsApiError(false);
                if (continueId == 0) {
                    // reset open bet in sessionStorage
                    sessionStorage.setItem('openBets', '{}');
                    esDataRequestLoading.current = true;
                    if (firstLoad) {
                        esValues.current = [];
                        esStates.current = {};
                    }
                    else {
                        // partialClearEsState();
                    }
                }
                let newData = {};
                let contId = -1;
                for (var i = 0; i < data.GetOpenBetsResult?.length; i++) {
                    newData[data.GetOpenBetsResult[i].Key] = data.GetOpenBetsResult[i].Value;
                }
                if (newData.balance_status == -2 || newData.sso_last_extend_status == -1) {
                    ssoLogoutBoardcast?.postMessage('ssoLogoutNow');
                    window.alert(t('LB_BS_SSO_SIGN_OUT_PASSIVE'));
                } else if (newData.result > '0') {
                    setEsErrorMsg('IB_ERROR_' + newData.result);
                    if (['103', '106', '111', '120', '180'].includes(newData.result)) {
                        ssoLogoutBoardcast?.postMessage('ssoLogoutNow');
                        window.alert(t('LB_BS_SSO_SIGN_OUT_PASSIVE'));
                    }
                } else {
                    if (newData.OpenBets) {
                        let obObj = JSON.parse(newData.OpenBets);
                        contId = obObj.ContinueId;
                        if (obObj.ContinueId > 0) {
                            await  getOpenBetsRequest(obObj.ContinueId, firstLoad, pushData);
                        }
                        var storedOb = sessionStorage.getItem('openBets');
                        if (storedOb != '{}') {
                            var openBets = JSON.parse(storedOb);
                            openBets.ContinueId = obObj.ContinueId;
                            openBets.Betlines = [...openBets.Betlines, ...obObj.Betlines];
                            openBets.Betlines = uniqueList(openBets.Betlines);
                            sessionStorage.setItem('openBets', JSON.stringify(openBets));
                        } else {
                            sessionStorage.setItem('openBets', newData.OpenBets);
                        }
                        sessionStorage.setItem('openBetVer', newData.openBetVer);
                        
                        if(continueId === 0){
                            esDataRequestLoading.current = false;
                            filterFBData(firstLoad, pushData);
                        }
                    }
                }
                if (contId == -1) {
                    setEsErrorMsg('');
                }
                return data;
            })
            .catch((err) => {
                setEsApiError(true);
                clearEsStateAll();
                return err;
            });
    };

    const filterOfferBets = (fbData) => {
        const openBets = JSON.parse(sessionStorage.getItem('openBets'));
        if (!openBets || !openBets.Betlines) {
            return [];
        }
        const bets = openBets.Betlines.map((item) => {
            let newItem = { ...item };
            newItem.legs.sort((x, y) => x.legOrder - y.legOrder);
            newItem.legs = newItem.legs.map((leg) => {
                let newLeg = { ...leg };
                let match = null;
                const openBetVer = sessionStorage.getItem('openBetVer');
                if ( openBetVer=="3" ) {
                    match = fbData.matches.filter((x) => parseInt(x.id) === leg.eventId)[0];
                }
                else {
                    match = fbData.matches.filter((x) => x.frontEndId === leg.frontendId)[0];
                }
                
                newLeg.match = match;
                
                if (match) {
                    newLeg.pool = match.foPools.filter((x) => parseInt(x.id) === leg.poolId)[0];
                    if (newLeg.pool) {
                        newLeg.combinations = newLeg.combinations.map((comb) => {
                            let newComb = {...comb};
                            newComb.line = newLeg.pool.lines.filter(
                                (x) => parseInt(x.lineId) === newComb.lineId
                            )[0];
                            newComb.comb = newComb.line.combinations.filter(
                                (x) => parseInt(x.combId) === newComb.combinationId
                            )[0];
                            return newComb;
                        }).sort((a, b)=>sortCombinations(a, b, newLeg.betType));
                    }
                }
                return newLeg;
            });
            return newItem;
        });
        esPendingForResults.current = [];
        return bets
            .filter((item) => {
                if (item.remainingBetTotal <= 0) {
                    return false;
                }

                if (isESTournBetType(item.legs[0].betType)) {
                    return false;
                }
                else {
                    if ( isAllLegSettled(item.legs) ) {
                        return false;
                    }
    
                    let offer = true;
                    for ( let idx in item.legs ) {
                        let leg = item.legs[idx];
                        if ( leg.match==null ) {
                            return false;
                        }
    
                        let tempCombOffer = false;
                        let isX1AllUp = item.allUpLevel > 1 && item.allUpFormula.endsWith("x1");
                        let isLosingAll = isLosingCombAll(leg);
                        if (isX1AllUp && isLosingAll) {
                            return false;
                        }
                        else {
                            if (anyWinComb(leg)) {
                                tempCombOffer = true;
                            }
                            else if (isIrrationalAll(leg)) {
                                tempCombOffer = true;
                            }
                            else if (isOfferAll(leg)) {
                                tempCombOffer = true;
                            }
                        }
                        let isSettled = isSettledLeg(leg, leg.match);
                        offer = offer && (leg.match?.esIndicatorEnabled || (!(isX1AllUp && isLosingAll) && isSettled)) && (isSettled || tempCombOffer);
                    }
                
                    // all up esStatus==0
                    if ( isAnyPendingForResult(item.legs, offer) ) {
                        esPendingForResults.current.push(item.uniqueTicketId);
                        return true;
                    }
    
                    return offer;
                }
                
            // }).map((item)=>{
            //     item.legs.sort(sortLegs);
            //     return item;
            }).sort(sortOpenBets);
    };

    const isESTournBetType = (bType)=> {
        return bType.match(/^(CHP|TQL|GPW|GPF|TPS|TSPC)$/);
    }


    const sortOpenBets = (xItem, yItem) => {
        let xlegs = [...xItem.legs];
        let ylegs = [...yItem.legs];
        let xLeg = xlegs.sort(sortLegs)[0];
        let yLeg = ylegs.sort(sortLegs)[0];
        let xMatch = xLeg.match;
        let yMatch = yLeg.match;
        let xSettle = isStopSellLeg(xMatch);
        let ySettle = isStopSellLeg(yMatch);
        if (xSettle && !ySettle) {
            return 1;
        }
        else if (!xSettle && ySettle) {
            return -1;
        }
        else if (xMatch.kickOffTime != yMatch.kickOffTime) return xMatch.kickOffTime < yMatch.kickOffTime ? -1 : 1;
        return xItem.accountTranNo - yItem.accountTranNo;
    };


    const sortLegs = (xLeg, yLeg) => {
        let xMatch = xLeg.match;
        let yMatch = yLeg.match;
        let xSettle = isStopSellLeg(xMatch);
        let ySettle = isStopSellLeg(yMatch);
        if (xSettle && !ySettle) {
            return 1;
        }
        else if (!xSettle && ySettle) {
            return -1;
        }
        else if (xMatch.kickOffTime != yMatch.kickOffTime) return xMatch.kickOffTime < yMatch.kickOffTime ? -1 : 1;
        return xLeg.legOrder < yLeg.legOrder ? -1 : 1;
    }

    const sortCombinations = (a, b, poolBetType) => {
        const betType= formatBetType(poolBetType);
        
        if(window.fbConfig['HandicapPools'].includes(betType)){
            return  0
        }
        if(a.combinationId > b.combinationId) {
            return 1
        }else if(a.combinationId < b.combinationId ) {
            return -1
        }
        return a.lineId - b.lineId
    }

    const getLatestWageringStatusUpdateTime = (leg) => {
        let t = '';
        leg.combinations.forEach(x => {
            if (t < x.wageringStatusUpdateTime) {
                t = x.wageringStatusUpdateTime;
            }
        });
        return t;
    }

    const filterFBData = async (firstLoad, pushData) => {
        let FBOddsPush = globalState.commonCtrl.enableOddsPushButton && window.globalConfig.FB_ODDS_PUSH
        if ( firstLoad || !FBOddsPush) {
            const openBets = JSON.parse(sessionStorage.getItem('openBets'));
            const openBetVer = sessionStorage.getItem('openBetVer');
            if ( openBetVer=="3" ) {
                let feIds = [...new Set(openBets.Betlines.map((x) => x.legs.map((y) => y?.eventId+'')).flat())];
                consoleLog('feId: ' + feIds,openBets.Betlines);
                if (feIds.length > 0) {
                    await loadMatchByFrontEndIDs({ page: 'CASHOUT', matchIds: feIds, earlySettlementOnly: true, showAllMatch: true }, pushData);
                }
            }
            else {
                let feIds = [...new Set(openBets.Betlines.map((x) => x.legs.map((y) => y?.frontendId+'')).flat())];
                consoleLog('feId: ' + feIds,openBets.Betlines);
                if (feIds.length > 0) {
                    await loadMatchByFrontEndIDs({ page: 'CASHOUT', frontEndIds: feIds, earlySettlementOnly: true, showAllMatch: true }, pushData);
                }
            }
        }
        else {
            filterFBDataInner(pushData);
        }
        partialClearEsState()
    };
    
    // const filterFBData = (firstLoad, pushData) => {
    //     if ( firstLoad ) {
    //         const openBets = JSON.parse(sessionStorage.getItem('openBets'));
    //         let feIds = [...new Set(openBets.Betlines.map((x) => x.legs.map((y) => y.frontendId)).flat())];
    //         consoleLog('feId: ' + feIds);
    //         if (feIds.length > 0) {
    //             loadMatchByFrontEndIDs({ page: 'CASHOUT', frontEndIds: feIds, earlySettlementOnly: true, showAllMatch: true }, pushData);
    //         }
    //     }
    //     else {
    //         filterFBDataInner(pushData);
    //     }
    // };

    const filterFBDataInner = (pushData) => {
        if(esDataRequestLoading.current){
            return ;
        }
        let filterData = filterOfferBets(esContentRef.current.matchData);
        consoleLog('ESFilterData', filterData);
        dataRef.current = filterData;
        pushData?.pubESInit();
        setEsLoading(false)
        forceUpdate();
    }

    const resetEsValueIndicator = (newValues) => {
        esValues.current = [
            ...newValues.map((newValue) => {
                let oldValue = esValues.current.filter((x) => parseInt(x.betId) === parseInt(newValue.betId))[0];
                newValue.indicator = 0;
                if (oldValue && !oldValue?.blockedForSell && !newValue?.blockedForSell) {
                    newValue.indicator = newValue.cashoutValue - oldValue.cashoutValue;
                }
                return newValue;
            })
        ];
    };

    const parseEsMessage = (topic, message) => {
        consoleLog('esMessage', message);
        if (topic.indexOf('cal_val') >= 0) {
            if (message.values) {
                resetEsValueIndicator(message.values);
                forceUpdate();
            }
        } else if (topic.indexOf('acpt_upd') >= 0) {
            submittedEsBets.current = submittedEsBets.current.filter(x=> x!=parseInt(message.betId));
            switch (message.resultStatus) {
                case 'SUCCESS':
                    updateEsState(message.betId, 'successful');
                    let item = dataRef.current.filter((x) => parseInt(x.uniqueTicketId) === parseInt(message.betId))[0];
                    if (item) {
                        let newEsSettleds = [...esSettleds.current];
                        let dt = moment();
                        item.dt = dt;
                        item.dateTime = dt.format('YYYY-MM-DD HH:mm');
                        item.esValue = message.soldFor;
                        newEsSettleds.push(item);
                        newEsSettleds.forEach((item)=>{
                            item.legs.sort((x,y)=> {
                                return y.legOrder < x.legOrder ? 1 : -1;
                            });
                        })
                        newEsSettleds.sort((x,y)=> {
                            if ( x.dt!=y.dt ) {
                                return x.dt < y.dt ? 1 : -1;
                            }
                            return y.accountTranNo - x.accountTranNo;
                        });
                        esSettleds.current = newEsSettleds;
                    }
                    let esValue = esValues.current.filter((x) => parseInt(x.betId) === parseInt(message.betId))[0];
                    if (esValue) {
                        esValue.indicator = 0;
                        esValue.cashoutValue = message.soldFor;
                    }
                    updateToastMsgs(message.betId, 'successful', '+');

                    if ( submittedEsBets.current.length==0 ) {
                        refreshBetSlipBalance();
                    }
                    break;
                case 'CASHOUT_VALUE_TOO_HIGH':
                    updateEsState(message.betId, 'jsEarlySettlementRejected1');
                    esValue = esValues.current.filter((x) => parseInt(x.betId) === parseInt(message.betId))[0];
                    esValue.indicator = 0;
                    if (esValue) {
                        esValue.indicator = message.calculatedValue - message.requestedValue;
                        esValue.cashoutValue = message.calculatedValue;
                    }
                    updateToastMsgs(message.betId, 'jsEarlySettlementRejected1', '+');
                    break;
                case "INVALID_INPLAY_FLAG":
                case "AP_TIMEOUT":
                case "BET_BLOCKED_FOR_SELL":
                    updateEsState(message.betId, 'jsEarlySettlementRejected2');
                    // esValue = esValues.current.filter((x) => parseInt(x.betId) === parseInt(message.betId))[0];
                    // esValue.indicator = 0;
                    // if (esValue) {
                    //     esValue.blockedForSell = true;
                    // }
                    updateToastMsgs(message.betId, 'jsEarlySettlementRejected2', '+');
                    break;
                case "AP_TICKET_ALREADY_EARLY_SETTLED":
                    updateEsState(message.betId, 'jsEarlySettlementRejected3');
                    updateToastMsgs(message.betId, 'jsEarlySettlementRejected3', '+');
                    break;
                case "BET_DOES_NOT_EXIST":
                case "AP_NO_VALID_TICKET_FOUND":
                case "COS_BET_ALREADY_RESULTED":
                case "AP_DIVIDEND_ERROR":
                case "CASHOUT_DISABLED":
                case "UNDEFINED":
                case "SYSTEM_ERROR":
                case "INTERNAL_TIMEOUT":
                case "BET_ALREADY_SOLD":
                case "AP_UNKNOWN_ERROR":
                case "COS_SYSTEM_ERROR":
                case "BET_SELL_ODDS_VALIDATION_FAILED":
                case "NON_POSITIVE_SELL_PRICE":
                case "TOO_MANY_PARTIAL_SELLS":
                case "PARTIAL_SELL_VALUE_TOO_SMALL":
                case "PARTIAL_STAKE_SOLD_TOO_SMALL":
                case "PARTIAL_STAKE_REMAINING_TOO_ SMALL":
                case "BET_SELL_ODDS_VALIDATION_LEGS_DO_NOT_MATCH":
                case "BET_ALREADY_SETTLED":
                case "INVALID_STAKE_FRACTION":
                case "N/A":
                    updateEsState(message.betId, 'jsEarlySettlementRejected4');
                    updateToastMsgs(message.betId, 'jsEarlySettlementRejected4', '+');
                    break;
                case "AP_SELECTIVE_REFUND_TICKET_ERROR":
                case "AP_SUPPLEMENTARY_PAYOUT_TICKET_ERROR":
                    updateEsState(message.betId, 'jsEarlySettlementRejected6');
                    updateToastMsgs(message.betId, 'jsEarlySettlementRejected6', '+');
                    break;
                default:
                    updateEsState(message.betId, 'rejected');
                    updateToastMsgs(message.betId, 'rejected', '+');
                    break;
            }
        }
    };

    const refreshBetSlipBalance = async () => {
        const resData = await getBalanceValue();
        if (resData?.balance) {
            let newBalance = textFormatAmountFn(resData.balance);
            refreshBalance?.postMessage(newBalance);
        }
    };

    const updateEsState = (betId, st) => {
        let newEsStates = { ...esStates.current };
        newEsStates[betId] = st;
        esStates.current = newEsStates;
        forceUpdate();
    };

    const clearEsStateById = (betId) => {
        let newEsStates = { ...esStates.current };
        newEsStates[betId] = "";
        esStates.current = newEsStates;
    }
    
    const clearEsState = () => {
        esStates.current = {};
    }

    const clearEsValue = (betId) => {
        esValues.current = esValues.current.filter(x=> x.betId!=betId)
    }

    const partialClearEsState = () => {
        let newEsStates = {};
        for ( let idx in esStates.current ) {
            if ( ['preview', 'loading'].includes(esStates.current[idx]) ) {
                newEsStates[idx] = esStates.current[idx];
            }
        }
        esStates.current = newEsStates;
    };

    const updateToastMsgs = (id, st, type) => {
        let newMsgArr = [...esToastMsgs];
        if (type === '+') {
            if (newMsgArr?.length < 3) newMsgArr.push({ id: id, st: st });
        }
        if (type === '-') {
            newMsgArr = newMsgArr.filter((x) => x.id != id);
        }
        setEsToastMsgs(newMsgArr);
    };

    const clearEsStateAll = () => {
        dataRef.current = [];
        esValues.current = [];
        esStates.current = {};
        esSettleds.current = [];
        esPendingForResults.current = {};
        setEsToastMsgs([]);
    };

    const copyContentRef = (contentRef) => {
        esContentRef.current = {
            betType: contentRef.current.betType
            , rawTournList: contentRef.current.rawTournList
            , rawMatchList: contentRef.current.rawMatchList
            , listData: contentRef.current.listData
            , matchData: esContentRef.current.matchData
            , tournData: contentRef.current.tournData
            , page: contentRef.current.page
            , selection: contentRef.current.selection
            , oddsSelection: contentRef.current.oddsSelection
            , config : contentRef.current.config
            , offset: contentRef.current.offset
            , isESContent: true
        }
    }

    return {
        esLoading,
        data: dataRef.current,
        esValues: esValues.current,
        esStates: esStates.current,
        esSettleds: esSettleds.current,
        esPendingForResults: esPendingForResults.current,
        esToastMsgs,
        esErrorMsg,
        esApiError,
        esContentRef,
        submittedEsBets,
        getEsData,
        getOpenBetsRequest,
        filterFBData,
        parseEsMessage,
        updateEsState,
        updateToastMsgs,
        clearEsState,
        clearEsValue,
        clearEsStateById,
        clearEsStateAll,
        copyContentRef,
        filterFBDataInner,
        forceUpdate
    };
};
