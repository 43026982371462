import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import RCResultTable from '../Common/RCResultTable';
import FO_Result from '../FO/FO_Result';
import DividendTable from '../Common/DividendTable';
import ComingleMsg from '../Common/ComingleMsg'
import { getComingleMsgs, isVoidedRace, isAbandonedRace, getIsShowPmuText } from '../Common/Common';
import { isLocalMeeting } from '../../Common/home-common';
import { RacingContext } from '../../Home/RacingPage';
import { useWindowSize } from '../../Common/CommonHooks';
import Loading from '../../Common/ui-components/Loading';

const Results = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const race = mtg.races.filter(x=> x.no==context.content.raceNo)[0] || {};
    const isVoidRace = isVoidedRace(context.content, mtg, context.content.raceNo);
    const isAbandonRace = isAbandonedRace( mtg, context.content.raceNo);
    const isDivReady = mtg.resPools.filter(x=> x.leg.races[x.leg.races.length-1]==context.content.raceNo
        && (x.status=="PAYOUT" || x.status.indexOf("REFUND")>=0)).length > 0;
    const isBettingOffer = mtg.poolInvs.filter(x=> x.leg.races[x.leg.races.length-1]==context.content.raceNo).length > 0;

    const hasRaceResult = () => {
        return (race?.judgeSigns?.filter(x=> x.value_en=="WEIGHED IN").length > 0
        || !isBettingOffer) && race.status=="RESULT";
    }
    const hasFOResult = () => {
        return mtg.foPools.filter(x=> x.status.toUpperCase().indexOf("REFUND")>=0 ||  x.selections[0]?.results?.find(y=> y.raceNo==context.content.raceNo)).length>0;
    }
    const isPMResultReady = hasRaceResult();
    const isResultReady = hasRaceResult() || hasFOResult();

    const getMessage = () => {
        if(!isBettingOffer && !isPMResultReady &&  !isVoidRace && !isAbandonRace) return   t(`LB_RC_NO_BETTING_SERVICE`);
        if(!isBettingOffer && (isVoidRace || isAbandonRace)) return   t(`LB_RC_RESULT_NOT_OFFERED_${isAbandonRace ? 'ABANDON' : 'VOID'}`);
        if((isVoidRace || isAbandonRace) && !hasRaceResult() && isBettingOffer) return isAbandonRace ? t('LB_RC_RESULT_MSG_ABANDON') : t('LB_RC_RESULT_MSG_VOID');
        if(!isResultReady && !isDivReady) return   t('LB_RC_RESULT_MSG_NOTREADY');
        return null;
    }

    if (context == null) {
        return <Loading/>
    } else {
        const isShowPmu = getIsShowPmuText(mtg, context.content.raceNo)
        let tmpMsgs = getComingleMsgs(mtg, "RESULTS", context.content.raceNo);

        let comingleMsg1 = [];
        if (tmpMsgs != null) {
            let comingleLang = i18n.language == "en" ? "E" : "C";
            comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}1: </span>{tmpMsgs['NOTE3_' + comingleLang]}</div>);
        }

        const { isMobileSize } = useWindowSize();
        const columnsCountBreakPoints = isMobileSize ? { 320: 1 } : { 350: 2 }

        return (
            <section className="RESULTS">
                <div style={{ padding: "12px 0px 0px 8px"}} id={'results_msg_top'}>{getMessage()}</div>
                {hasRaceResult() ? <RCResultTable /> : null }
                <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints} style={{ margin: "12px 0px" }}>
                    <Masonry className="masonry-col">
                        <div>
                            <DividendTable type={'singleRacePool'} />
                            {isDivReady && isShowPmu ? (
                            <div className="rc-comingle-remarks">
                                <ComingleMsg></ComingleMsg>
                            </div>) : null}
                        </div>
                        <div>
                            <DividendTable type={'multiRacePool'} />
                            <FO_Result betType="JKC"/>
                            <FO_Result betType="TNC"/>
                        </div>
                    </Masonry>
                </ResponsiveMasonry>
                {!isLocalMeeting(mtg.venueCode) && (isPMResultReady || isDivReady) ? <div style={{ padding: "0px 0px 0px 8px"}} id={'results_msg_bottom'}>{t('LB_RC_RESULT_REMARK_SIMULCAST')}</div> : null}
            </section>
        );
    }
}
export default Results;