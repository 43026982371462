import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetFootballConfig, GetRaceConfig, GetBetslipConfig } from '../Common/ConfigHelper';
import { IsPassBetlinesValidFn } from './BetlinesValidFunc';
import { GlobalContext } from '../../contexts/GlobalContext';
import { GetChannelPara, IsBetTypeEnabled, IsFlexibetEnabled } from '../Common/ChannelParaFunc';
import { getSessionId, sendBetApi, getBalance } from './BetSlipAPI';
import { saveBetLinesToCurrentSessionRecords } from '../Common/CurrentSessionRecords';
import { AddToSlipAnimationFn, updateOriginLocation } from '../Common/ui-components/AddToSlipAnimation';
import { useWindowSize, useShowAlertDialog } from '../Common/CommonHooks';
import { glassboxCustomEvent } from '../Common/utils/glassbox';
import { getSessionId_IB, getBalance_IB, sendBetApi_IB } from './BetSlipIBAPI';
import * as LoginHookObj from '../BetSlip/LoginHooks';
import * as LoginIBHookObj from '../BetSlip/LoginIBHooks';
import { isEoD } from '../Common/GlobalFunc';
const { GetLoginStatus } = window.globalConfig.IS_IB ? LoginIBHookObj : LoginHookObj;
import { compressToUTF16 } from 'lz-string';
import { GetError } from '../Common/bs_str_table';

export const MyBetSlipContext = React.createContext([]);

export const getBalanceValue = async (isOpener = false) => {
    const accessToken = window.sessionStorage.getItem('access_token') || '';

    if (window.globalConfig.IS_IB) {
        const data = await getBalance_IB(isOpener);

        if (data?.balance != null && data?.balance != '') {
            sessionStorage.setItem('balance', data?.balance);
        }
        return data;
    }

    const sessionData = await getSessionId(accessToken);
    if (sessionData?.sessionId) {
        const balance = await getBalance({ sessionData, accessToken });
        if (balance?.balance && balance?.accountNo) {
            return balance;
        }
    }
    return null;
};

export const useBetSlip = () => {
    const {
        betBaskets,
        setBetBaskets,
        currentBasket,
        setCurrentBasket,
        balance,
        setBalance,
        isSpeedbet,
        setIsSpeedbet,
        betBaskets_0,
        firstTimeFromJCRWRedirect
    } = useContext(MyBetSlipContext);
    const globalContext = useContext(GlobalContext);
    const { t, i18n } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const [panelAllupArr, setPanelAllupArr] = useState([]);
    const panelAllupRef = useRef()
    panelAllupRef.current = panelAllupArr;
    const [isShowPreviewInfo, setIsShowPreviewInfo] = useState(false);
    const [isShowConfirmation, setIsShowConfirmation] = useState(false);
    const [confirmationStatus, setConfirmationStatus] = useState({
        status: null,
        isResendBet: false,
        accepted: 0,
        rejected: 0,
        unknown: 0
    });
    const [isShowFTInplayDialog, setIsShowFTInplayDialog] = useState(false);
    const speedbetBasket = window.globalConfig.SPEEDBET_BASKET;
    const [betTotal, setBetTotal] = useState();
    const [isShowError, setIsShowError] = useState(false);
    const showErrorRef = useRef();
    const [sendBetTime, setSendBetTime] = useState(null);
    const [panelAllupData, setPanelAllupData] = useState({
        unitBet: 10,
        noOfComb: '-',
        dividend: '-',
        netReturn: '-',
        alupFormula: ''
    });
    const FBConfig = GetFootballConfig();
    const HRConfig = GetRaceConfig();
    const { getAlertShowMode } = useShowAlertDialog();

    const [disablePreviewConfirm,setDisablePreviewConfirm] = useState(false)

    useEffect(() => {
        let initAllupArr = [];
        speedbetBasket.map((item) => {
            let obj = {
                name: item,
                allupType: '',
                subTypeList: [],
                allupMaxLeg: 8,
                allupGroup: 1,
                uniqueMatchIdList: [],
                allupCheckList: []
            };
            initAllupArr.push(obj);
        });
        setPanelAllupArr(initAllupArr);
    }, []);

    useEffect(() => {
        if (isShowError) {
            clearInterval(showErrorRef.current);
            showErrorRef.current = setTimeout(() => {
                setIsShowError(false);
            }, 3000);
        }
        return () => {
            clearInterval(showErrorRef.current);
        };
    }, [isShowError]);

    /**
     *
     * @param {Array<Object>} newBetlines bet lines
     */

     const addToBetSlip = (newBetlines, needDelBetList = []) => {
        let betSlipInfo = {
            acceptResult: false,
            acceptType: 'all',  // all, part
            acceptBetSlipLength: GetBetslipConfig()?.maxBetlines,
        }
        if (!GetBetslipConfig()) return betSlipInfo;
        if (isEoD()) return betSlipInfo; //check is not EoD
        const { betslipState } = globalContext.globalState;
        if (betslipState != 0) {
            if (betslipState == 1) {
                getAlertShowMode(t('LB_BS_ADDTOSLIP_IS_EKBA'));
            } else if (betslipState == 3) {
                getAlertShowMode(t('LB_BS_ADDTOSLIP_IS_BETTING'));
            } else {
                getAlertShowMode(t('LB_BS_ADDTOSLIP_IS_OTINACTIVE'));
            }
            return betSlipInfo;
        }

        if (GetLoginStatus?.()?.isBusy) {
            getAlertShowMode(t('LB_BS_ADDTOSLIP_BUSY'));
            return betSlipInfo;
        }
        let hasMaxiMumTips = false;
        if (betBaskets[currentBasket].length + newBetlines.length > GetBetslipConfig()?.maxBetlines) {
            getAlertShowMode(t('LB_BS_ADDTOSLIP_MAXIMUM'));
            hasMaxiMumTips = true;
            if (betBaskets[currentBasket].length >= GetBetslipConfig()?.maxBetlines) {
                return betSlipInfo;
            }
        }

        if (!newBetlines?.length) return betSlipInfo;
        let newData = [...betBaskets[currentBasket]];

        const canAddBetLength = GetBetslipConfig()?.maxBetlines - betBaskets[currentBasket].length;
        const paramBetLength = newBetlines.length;
        if (newBetlines.length - canAddBetLength > 0) {
            newBetlines = newBetlines.slice(0, canAddBetLength);
        }
        let isParaEnabled = true;
        let isFlexiEnabled = true;
        let isUnitBetValid = true;
        newBetlines.forEach((line) => {
            if (!isBetLineEnabledParaCheck(line)) {
                isParaEnabled = false;
                return;
            }

            if (line.isFlexi && !IsFlexibetEnabled(line.betType)) {
                isFlexiEnabled = false;
                return;
            }

            if (line.unitBet > Number(GetChannelPara('MaxBetUnit'))) {
                isUnitBetValid = false;
                return;
            }

            if (betBaskets[currentBasket].find((item) => item.id === line.id && item.prod === 'FB')) return false;
            let showAllUpCheckBox = false;

            if (IsAllUpEnabled(line.prod) && isAllUpEnabledWithBetType(line.betType)) {
                showAllUpCheckBox = true;
            }
            const newBetSlipItem = {
                betObj: line,
                id: line.id,
                prod: line.prod,
                showAllUpCheckBox,
                allUpChecked: false,
                isExpended: false,
                isCounterOffer: false,
                isAutoAcceptance: false,
                unitBet: line.unitBet || 10
            };
            newData = [...newData, newBetSlipItem];
        });
        if (needDelBetList.length > 0) {
            newData = newData.filter((item) => !needDelBetList.includes(item.id));
        }
        if (!isParaEnabled) {
            getAlertShowMode(t('LB_BS_ADDTOSLIP_ERR_NOT_AVAILABLE'));
            return betSlipInfo;
        }

        if (!isFlexiEnabled) {
            getAlertShowMode(t('LB_BS_ADDTOSLIP_ERR_NO_FLEXI'));
            return betSlipInfo;
        }

        if (!isUnitBetValid) {
            getAlertShowMode(t('LB_BS_ADDTOSLIP_ERR_EXCEE_AMT'));
            return betSlipInfo;
        }

        if (IsPassBetlinesValidFn(newData) > GetBetslipConfig()?.maxBetlineChar) {
            !hasMaxiMumTips && getAlertShowMode(t('LB_BS_ADDTOSLIP_MAXIMUM'));
            newData = verifyMaxChar(newData, GetBetslipConfig().maxBetlineChar, canAddBetLength)
        }
        betSlipInfo.acceptBetSlipLength = newData.length - betBaskets[currentBasket].length;
        if (betSlipInfo.acceptBetSlipLength == 0) {

            return betSlipInfo
        } else if (betSlipInfo.acceptBetSlipLength != paramBetLength) {
            betSlipInfo.acceptType = 'part'
        }
        betSlipInfo.acceptResult = true;
        const isOpenBetslipPanel = document.querySelector('#divBetSlipNormal')?.offsetWidth > 0;
        if (isMobileSize && !isOpenBetslipPanel) {
            if (globalContext.isShowMobileBottomMenu) {
                setTimeout(() => {
                    AddToSlipAnimationFn(newBetlines.length, () => {
                        globalContext?.setIsWaitMobileFootballAllupCal?.(false);
                    });
                }, 100);
            } else {
                var element = document.querySelector('.bottom-menu');
                const handle = function () {
                    // updateOriginLocation(`.bottom-menu .AddToSlip`);
                    AddToSlipAnimationFn(newBetlines.length, () => {
                        globalContext?.setIsWaitMobileFootballAllupCal?.(false);
                    });
                    element.removeEventListener('animationend', handle);
                };
                element.addEventListener('animationend', handle);
                globalContext.setIsShowMobileBottomMenu(true);
            }
        }
        updateCurrentBasket(newData);
        return betSlipInfo;
    };

    const verifyMaxChar = (list, maxChar, num) => {
        if (num == 0) {
            return list;
        }
        if (IsPassBetlinesValidFn(list) > maxChar) {
            const newList = list.slice(0, -1);
            return verifyMaxChar(newList, maxChar, num - 1)
        } else {
            return list;
        }
    }

    const updateResendBetLine = (newData) => {
        let currentBasketData = [...betBaskets[currentBasket]].map((item) => {
            if (item.id === newData.id) {
                return newData;
            }
            return item;
        });
        updateCurrentBasket(currentBasketData);
    };

    const updateCurrentBasket = (newData) => {
        const baskets = [...betBaskets];
        baskets[currentBasket] = newData;
        setBetBaskets(baskets);
        // if (window.racingConfig["redirectIsReplaceWindow"]) {
        //     sessionStorage.setItem('betBaskets', compressToUTF16(JSON.stringify(baskets)));
        // }
        console.log('baskets', baskets);
    };

    const clearBetSlip = () => {
        setCurrentBasket(0);
        const noOfBetslipTabs =
            window.globalConfig.IS_SPEEDBET == true ? window.globalConfig.SPEEDBET_BASKET.length : 1;
        setBetBaskets(Array(Number(noOfBetslipTabs)).fill([]));
        resetPanelAllupFn('all');
    };

    const clearCurrentBasket = (option = {}) => {
        let newData = [];
        let isResend = option.isResend
        let clearAll = option.clearAll
        if (isResend) {
            newData = betBaskets[currentBasket].filter((i) => i.isCounterOffer || i.betObj.sellStatus == 'SUSPENDED' );

            //clear old counter offer values
            newData.map((item) => {
                if (item['oldUnitBet']) {
                    delete item['oldUnitBet'];
                }
                item.betObj?.combs?.map((comb) => {
                    if (comb['oldOddsVal']) {
                        delete comb['oldOddsVal'];
                    }
                });
                item.betObj?.betList?.map((betList) => {
                    betList.combs?.map((comb) => {
                        if (comb['oldOddsVal']) {
                            delete comb['oldOddsVal'];
                        }
                    });
                });
            });
        }else if(!clearAll){
            newData = betBaskets[currentBasket].filter((i) =>  i.betObj.sellStatus == 'SUSPENDED' )
        }

        updateCurrentBasket(newData);
        resetPanelAllupFn();
    };

    const isFlexiEnsabledParaCheck = (line) => {
        if (line.prod == 'HR') {
            const isEnabled = GetChannelPara('FlexiHR') == 1 ? true : false;
            return isEnabled;
        }

        return true;
    };

    const isBetLineEnabledParaCheck = (line) => {
        if (line.betType == undefined || line.betType == null || line.betType == '') return false;

        let isValid = true;
        if ([...HRConfig.ALUP_PAGE, 'CWAALLALUP'].includes(line.betType) || line.betType == 'ALUP') {
            if (!IsAllUpEnabled(line.prod)) {
                isValid = false;
            }

            if (!IsAllUpBetlineEnabled(line)) {
                isValid = false;
            }

            // line.betList.map((bet) => {
            //     if (!isAllUpEnabledWithBetType(bet.betType)) {
            //         isValid = false;
            //         return;
            //     }
            //     if (!IsBetTypeEnabled(bet.betType)) {
            //         isValid = false;
            //         return;
            //     }
            // });
        } else if (!IsBetTypeEnabled(line.betType)) {
            isValid = false;
        }
        /*
        if (line.unitBet > Math(GetChannelPara("MaxBetUnit"))) {
            getAlertShowMode(GetError("1206"));
            return cRetCodeFail;
        }
*/
        return isValid;
    };

    function IsAllUpEnabled(prod) {
        if (prod == 'FB' && !IsBetTypeEnabled('CrossPoolBet')) return false;
        else if (prod == 'HR' && !IsBetTypeEnabled('HorseRaceCrossPool')) return false;
        return true;
    }

    function IsAllUpBetlineEnabled(line) {
        var isSingleAlup = true;
        let betTypes = [...new Set(line.betList.map(i => i.betType))]
        let isCorssPool = betTypes.length > 1
        var firstBetType = line.betList[0].betType;
        firstBetType = TranslateBetType(firstBetType);
        let isET = line.betList.some(i => window.fbConfig['ExtraTimePools'].includes(i.betType))
        if(isET && GetChannelPara('ExtraTime') != 1)   return false
        if(isCorssPool){
            for (var i = 0; i < betTypes.length; i++) {
                if(!GetXPoolAllUpEnabled(betTypes[i])){
                    return false
                }
            }
        } else if (
            line.prod == 'FB' &&
            IsValidFBBetType(firstBetType) &&
            (!IsBetTypeEnabled('Alup' + firstBetType) || !IsBetTypeEnabled(firstBetType))
        ){
            return false;
        }else if (line.prod == 'HR' && (!IsBetTypeEnabled(betTypes[0]) || !IsBetTypeEnabled('CrossPool' + betTypes[0]))){
            return false
        }
        return true;
    }

    function isCwinPool(betType) {
        if (['CWA', 'CWB', 'CWC'].includes(betType)) {
            return true;
        }
        return false;
    }

    function IsValidFBBetType(betType) {
        const hasKey = FBConfig.allupInfo[betType];
        if (hasKey) {
            return true;
        }
        return false;
    }

    function isAllUpEnabledWithBetType(betType) {
        betType = TranslateBetType(betType);
        switch (betType) {
            case 'HAD':
            case 'HDC':
            case 'TTG':
            case 'CRS':
            case 'FCS':
            case 'HFT':
            case 'OOE':
            case 'HIL':
            case 'FGS':
            case 'HHA':
            case 'GPF':
            case 'GPW':
            case 'FHA':
            case 'FTS':
            case 'FHL':
            case 'NTS':
            case 'CHL':
            case 'FCH':
            case 'FHH':
            case 'FHC':
            case 'CHD':
            case 'ECD':
            case 'EHH':
                return IsBetTypeEnabled('Alup' + betType);

            case 'WIN':
            case 'PLA':
            case 'W-P':
            case 'QIN':
            case 'QPL':
            case 'QQP':
            case 'CWA':
            case 'CWB':
            case 'CWC':
            case 'TRI':
                return IsBetTypeEnabled('CrossPool' + betType);
            case 'FCT':
                return GetXPoolAllUpEnabled('FCT');
        }
        return false;
    }

    function GetXPoolAllUpEnabled(betType) {
        betType = TranslateBetType(betType);
        if (RegExp(/^(CWB|CWC)$/).test(betType)) {
            return false;
        } else if (
            // IsBetTypeEnabled(betType) &&
            IsBetTypeEnabled('CrossPool' + betType) &&
            IsBetTypeEnabled('CrossPoolBet')
        ) {
            return true;
        } else if (
            // IsBetTypeEnabled(betType) &&
            IsBetTypeEnabled('CrossPool' + betType) &&
            IsBetTypeEnabled('HorseRaceCrossPool')
        ) {
            return true;
        }
        return false;
    }

    const TranslateBetType = (betType) => {
        var tp = betType;
        switch (betType) {
            case 'EHA':
                tp = 'HAD';
                break;
            case 'EDC':
                tp = 'HDC';
                break;
            case 'EHL':
                tp = 'HIL';
                break;
            case 'ECH':
                tp = 'CHL';
                break;
            case 'ECS':
                tp = 'CRS';
                break;
            case 'ENT':
                tp = 'NTS';
                break;
            case 'ETG':
                tp = 'TTG';
                break;
        }

        return tp;
    };

    /**
     * @param {String} id bet line id
     * */
    const removeBetLinesItem = (id) => {
        const newData = betBaskets[currentBasket].filter((item) => item.id !== id);
        updateCurrentBasket(newData);
        //setBetLines(newData)
    };

    /**
     * @param {object} itemData  oldBetline --> itemData
     * */
    const updateBetLinespData = (itemData) => {
        const newData = betBaskets[currentBasket].map((item) => {
            if (itemData.id === item.id) {
                return itemData;
            }
            return item;
        });
        updateCurrentBasket(newData);
        //setBetLines(newData)
    };

    const computePanelAllupFunc = (betlines) => {
        const curBetLine = betlines[currentBasket];
        let curPanelAllupObj = { ...panelAllupRef.current[currentBasket] };
        if(curBetLine.length === 0 || curPanelAllupObj.allupCheckList?.length === 0){
            return ;
        }
        let allupInitObj = {
            name: currentBasket,
            allupType: '',
            subTypeList: [],
            allupMaxLeg: 8,
            allupGroup: 1,
            uniqueMatchIdList: [],
            allupCheckList: []
        };
        let stopSellBet = curBetLine.filter(item => {
            const { betObj } = item;
            return betObj?.isStopSell;
        })
        if (stopSellBet.length > 0) {
            if (curPanelAllupObj.allupType?.length === 0) {
                curPanelAllupObj.allupType = curPanelAllupObj.allupCheckList[0].prod;
            }
            stopSellBet.map(item => {
                const { betObj } = item;
                curPanelAllupObj.allupCheckList = curPanelAllupObj.allupCheckList.filter((item) => {
                    return item.id !== betObj.id;
                })
                if (curPanelAllupObj.allupCheckList.length === 0) {
                    curPanelAllupObj = {
                        ...allupInitObj
                    };
                } else {
                    curPanelAllupObj.uniqueMatchIdList = getUniqueMatchIdFn(
                        curPanelAllupObj.allupCheckList,
                        curPanelAllupObj.allupGroup === 2 && curPanelAllupObj.allupType === 'FB' ? 'raceno' : 'matchId'
                    );
                }
            })
            panelAllupRef.current[currentBasket] = curPanelAllupObj;
            setPanelAllupArr([...panelAllupRef.current]);
        }
    }

    const panelAllupCheckFn = (comb) => {
        const FBconfig = GetFootballConfig();
        const { TournamentAllupPools, AllupEnabledPools, MixAllup6LegPools } = FBconfig;
        const HRconfig = GetRaceConfig();
        const { AllupPools, Allup3LegPools } = HRconfig;
        const { betObj } = comb;
        let curPanelAllupObj = { ...panelAllupArr[currentBasket] };
        if (curPanelAllupObj.allupType.length === 0) {
            curPanelAllupObj.allupType = comb.prod;
        }
        let allupInitObj = {
            name: currentBasket,
            allupType: '',
            subTypeList: [],
            allupMaxLeg: 8,
            allupGroup: 1,
            uniqueMatchIdList: [],
            allupCheckList: []
        };

        if (curPanelAllupObj.allupType === 'FB') {
            if (curPanelAllupObj.subTypeList.length === 0) {
                if (TournamentAllupPools.includes(betObj.betType)) {
                    curPanelAllupObj.subTypeList = TournamentAllupPools;
                    curPanelAllupObj.allupGroup = 2;
                } else {
                    curPanelAllupObj.subTypeList = [...AllupEnabledPools];
                    curPanelAllupObj.allupGroup = 1;
                }
            }
            const newObj = {
                ...betObj,
                betType: betObj.betType,
                id: betObj.id,
                matchId: betObj.matchId,
                raceno: betObj?.combs?.[0]?.currentPool?.instNo
            };
            if (curPanelAllupObj.allupCheckList.length === 0) {
                curPanelAllupObj.allupCheckList.push(newObj);
            } else {
                let isFindId = curPanelAllupObj.allupCheckList.find((item) => {
                    return item.id === betObj.id;
                });
                if (isFindId) {
                    curPanelAllupObj.allupCheckList = curPanelAllupObj.allupCheckList.filter((item) => {
                        return item.id !== betObj.id;
                    });
                    if (curPanelAllupObj.allupCheckList.length === 0) {
                        curPanelAllupObj = {
                            ...allupInitObj
                        };
                    }
                } else {
                    curPanelAllupObj.allupCheckList.push(newObj);
                }
            }
            curPanelAllupObj.uniqueMatchIdList = [];
            if (curPanelAllupObj.allupCheckList.length > 0) {
                curPanelAllupObj.allupMaxLeg = curPanelAllupObj.allupCheckList.find((item) => {
                    return MixAllup6LegPools.includes(item.betType);
                })
                    ? 6
                    : 8;
                curPanelAllupObj.uniqueMatchIdList = getUniqueMatchIdFn(
                    curPanelAllupObj.allupCheckList,
                    curPanelAllupObj.allupGroup === 2 ? 'raceno' : 'matchId'
                );
            }
        }
        if (curPanelAllupObj.allupType === 'HR') {
            if (curPanelAllupObj.subTypeList.length === 0) {
                curPanelAllupObj.subTypeList = AllupPools;
                curPanelAllupObj.allupMaxLeg = 6;
            }
            const newObj = {
                ...betObj,
                betType: betObj.betType,
                id: betObj.id,
                matchId: betObj.meetingId,
                raceno: betObj.raceno
            };
            if (curPanelAllupObj.allupCheckList.length === 0) {
                curPanelAllupObj.allupCheckList.push(newObj);
            } else {
                let isFindId = curPanelAllupObj.allupCheckList.find((item) => {
                    return item.raceno === betObj.raceno;
                });
                if (isFindId) {
                    curPanelAllupObj.allupCheckList = curPanelAllupObj.allupCheckList.filter((item) => {
                        return item.raceno !== betObj.raceno;
                    });
                    if (curPanelAllupObj.allupCheckList.length === 0) {
                        curPanelAllupObj = {
                            ...allupInitObj
                        };
                    }
                } else {
                    curPanelAllupObj.allupCheckList.push(newObj);
                }
            }
            curPanelAllupObj.uniqueMatchIdList = [];
            if (curPanelAllupObj.allupCheckList.length > 0) {
                curPanelAllupObj.allupMaxLeg = curPanelAllupObj.allupCheckList.find((item) => {
                    return Allup3LegPools.includes(item.betType);
                })
                    ? 3
                    : 6;
                curPanelAllupObj.uniqueMatchIdList = getUniqueMatchIdFn(curPanelAllupObj.allupCheckList, 'raceno');
            }
        }
        const panelAllup = [...panelAllupArr];
        panelAllup[currentBasket] = curPanelAllupObj;
        setPanelAllupArr(panelAllup);
    };

    const getUniqueMatchIdFn = (arr, key = 'matchId') => {
        let obj = {};
        let newArr = [];
        arr.forEach((item) => {
            let newId = item[key];
            if (!obj[newId]) {
                newArr.push(newId);
                obj[newId] = true;
            }
        });
        return newArr;
    };

    const resetPanelAllupFn = (type = '') => {
        let newpanelAllupArr = [...panelAllupArr];
        // let initAllupArr = [];
        if (type === 'all') {
            newpanelAllupArr = [];
            speedbetBasket.map((item) => {
                let obj = {
                    name: item,
                    allupType: '',
                    subTypeList: [],
                    allupMaxLeg: 8,
                    allupGroup: 1,
                    uniqueMatchIdList: [],
                    allupCheckList: []
                };
                newpanelAllupArr.push(obj);
            });
        } else {
            newpanelAllupArr[currentBasket] = {
                name: currentBasket,
                allupType: '',
                subTypeList: [],
                allupMaxLeg: 8,
                allupGroup: 1,
                uniqueMatchIdList: [],
                allupCheckList: []
            };
        }
        setPanelAllupArr([...newpanelAllupArr]);
    };

    const updateBetTotal = (betLines) => {
        let totalBet = 0;
        let totalAmount = 0;
        betBaskets[currentBasket]?.map((item) => {
            if ( item.betObj.isStopSell ){
                // filter cannot Selling
            }else if (item.prod === 'FB' && item.betObj.betType === 'ALUP') {
                totalBet = totalBet + item.betObj.noOfComb;
                totalAmount = totalAmount + item.betObj.noOfComb * item.unitBet;
            } else if (item.prod === 'HR') {
                totalBet = totalBet + item.betObj.noOfBets;
                if (item.betObj.isFlexi && item.betObj.betTotal) {
                    totalAmount = totalAmount + parseInt(item.betObj.betTotal);
                } else {
                    totalAmount = totalAmount + item.betObj.noOfBets * item.unitBet;
                }
            } else {
                const multidraw = item.betObj.multidraw ? item.betObj.multidraw : 1;
                totalBet = totalBet + item.betObj.noOfBets * multidraw;
                totalAmount = totalAmount + item.betObj.noOfBets * item.unitBet * multidraw;
            }
        });
        setBetTotal({ totalBet, totalAmount });
        return { totalBet, totalAmount };
    };

    const getBetTotal = () => {
        return betTotal;
    };

    const sendBetRequest = async (access_token, seqNo, isRetry = false, isLB = false, isNew = true, setErrorMessage = () => {}) => {
        // send request
        if (globalContext.globalState.isSendingBet) return;
        globalContext.updateGlobalState({ isSendingBet: true });

        const bets = IsPassBetlinesValidFn(betBaskets[currentBasket], {validType:'sendbet'});
        let betLines = []
        let dimBetLines = []
        for (let i = 0; i < betBaskets[currentBasket].length; i++) {
            const element = betBaskets[currentBasket][i];
            if (element.betObj.isStopSell){
                dimBetLines.push(element)
                continue;
            }
            betLines.push(
                {
                    ...element,
                    betLineStr: bets[i]?.betline
                }
            )
        }
        const pushBets = IsPassBetlinesValidFn(betBaskets[currentBasket], {validType:'sendbet',pushFilter:true});

        const sessionData = window.globalConfig.IS_IB ? await getSessionId_IB(false) : await getSessionId(access_token);

        if (sessionData?.sessionId) {
            let sendBetOutput = window.globalConfig.IS_IB
                ? await sendBetApi_IB({ accessToken: access_token, sessionData, bets:pushBets, language: i18n.language, seqNo, isRetry, isLB, isNew })
                : await sendBetApi({ accessToken: access_token, sessionData, bets:pushBets, language: i18n.language });

            setErrorMessage?.(sendBetOutput?.betResult?.[0]?.resultMsg)
            if (sendBetOutput?.delay_time_in_seconds) {
                // inplay
                return sendBetOutput;
            }
            if (sendBetOutput?.sendbet_error && sendBetOutput?.sendbet_error.indexOf('159') >= 0 && !isRetry) {
                // retry
                return sendBetOutput;
            }
            if (sendBetOutput?.sendbet_status == '-2') {
                globalContext.updateGlobalState({ isSendingBet: false });
                return sendBetOutput;
            }
            if (sendBetOutput) {
                setSendBetTime(sendBetOutput.betTime || t('LB_BS_LOGIN_NO_TIME'));
                let acceptedCount = 0;
                let rejectedCount = 0;
                let betResult = sendBetOutput?.betResult;
                let isResendBet = false;
                let x = 0;
                betLines = betLines.map((item) => {
                    if(item.betObj.isStopSell)return item
                    const resultData = betResult ? betResult[x] : null; //?.find((i) => i.translatorBetline === item.betLineStr);
                    let resultMsg = resultData?.resultMsg;
                    let resultMsgChi = resultData?.resultMsgChi;
                    x++;
                    const resultBetInfo = resultData?.betInfo;
                    let showResendControlBtn = false;
                    item.isCounterOffer = false;

                    if (resultData?.result == 0) {
                        acceptedCount += 1;

                        if (resultBetInfo?.oddsFlag != null) {
                            if (resultBetInfo?.oddsFlag == '0') {
                                //auto acceptance
                                item.isAutoAcceptance = true;
                                updateBetLineOdds(item, resultBetInfo);
                            } else if (resultBetInfo?.oddsFlag == '3') {
                                // counter offer
                                rejectedCount += 1;
                                acceptedCount -= 1;
                                isResendBet = true;
                                showResendControlBtn = true;
                                item.isCounterOffer = true;
                                item.counterOfferData = {
                                    counterOfferKey: resultBetInfo.counterOfferKey,
                                    systemCounterOfferCount: resultBetInfo.systemCounterOfferCount
                                };
                                if (resultBetInfo?.counterOfferType == '1') {
                                    item.counterOfferOddsChange = true;
                                    item.counterOfferAmountChange = false;
                                    resultMsg = t('LB_BS_COUNTER_ODDS', { lng: 'en' });
                                    resultMsgChi = t('LB_BS_COUNTER_ODDS', { lng: 'ch' });
                                }
                                if (resultBetInfo?.counterOfferType == '2') {
                                    item.counterOfferOddsChange = false;
                                    item.counterOfferAmountChange = true;
                                    resultMsg = t('LB_BS_COUNTER_AMOUNT', { lng: 'en' });
                                    resultMsgChi = t('LB_BS_COUNTER_AMOUNT', { lng: 'ch' });
                                }
                                if (resultBetInfo?.counterOfferType == '3') {
                                    item.counterOfferOddsChange = true;
                                    item.counterOfferAmountChange = true;
                                    resultMsg = t('LB_BS_COUNTER_ODDS_AMOUNT', { lng: 'en' });
                                    resultMsgChi = t('LB_BS_COUNTER_ODDS_AMOUNT', { lng: 'ch' });
                                }
                                updateBetLineOdds(item, resultBetInfo);
                            }
                        }
                    } else if (resultData?.result > 0 || resultData?.result < 0) {
                        rejectedCount += 1;
                    }
                    betResult = betResult?.map((item) => {
                        /* if (item?.translatorBetline === resultData?.translatorBetline) {
                            return {};
                        }
                        */
                        return item;
                    });
                    return {
                        ...item,
                        resultCode: resultData?.result,
                        txNo: resultData?.txNo,
                        showResendControlBtn,
                        resultMsg: resultMsg,
                        resultMsgChi: resultMsgChi,
                        resultAmount: resultData?.totalInvAmt || 0,
                        flexiUnitBet: resultData?.flexiUnitBet
                    };
                });
                if (acceptedCount == 0 && rejectedCount == 0) {
                    setConfirmationStatus({
                        ...confirmationStatus,
                        status: 'unknown',
                        unknown: betLines.length
                    });
                    setBalance('---');
                } else {
                    let status = '';
                    if (acceptedCount === betLines.length) {
                        status = 'accepted';
                    } else if (rejectedCount === betLines.length) {
                        status = 'rejected';
                    } else {
                        status = 'unknown';
                        setBalance('---');
                    }
                    setConfirmationStatus({
                        status,
                        unknown: betLines.length - (acceptedCount + rejectedCount),
                        accepted: acceptedCount,
                        rejected: rejectedCount,
                        isResendBet
                    });
                }
            } else {
                setConfirmationStatus({
                    ...confirmationStatus,
                    status: 'unknown',
                    unknown: betLines.length
                });
                setBalance('---');
            }

            // set balance
            const newbalance = sendBetOutput?.newBalance;
            if (newbalance || newbalance == 0) {
                const formatted_newbalance = (+newbalance).toLocaleString(undefined, { minimumFractionDigits: 2 });
                setBalance(formatted_newbalance);
                sessionStorage.setItem('balance', newbalance);
            }
        } else {
            setConfirmationStatus({
                ...confirmationStatus,
                status: 'unknown',
                unknown: betLines.length
            });
            setBalance('---');
        }
        betLines.sort((a, b) => {
            let aOrderScore = 1;
            let bOrderScore = 1;
            if (a.resultCode === 0 && a.isCounterOffer) {
                // counter
                aOrderScore = 3;
            } else if (a.resultCode === 1) {
                // reject
                aOrderScore = 2;
            }
            if (b.resultCode === 0 && b.isCounterOffer) {
                // counter
                bOrderScore = 3;
            } else if (b.resultCode === 1) {
                // reject
                bOrderScore = 2;
            }
            return bOrderScore - aOrderScore;
        });

        saveBetLinesToCurrentSessionRecords(betLines);
        updateCurrentBasket([...betLines, ...dimBetLines]);
        setIsShowConfirmation(true);
        globalContext.updateGlobalState({ isSendingBet: false });
    };

    const betDoneFn = () => {
        setIsShowPreviewInfo(false);
        setIsShowConfirmation(false);
        setSendBetTime(null);
        setConfirmationStatus({
            status: null,
            accepted: 0,
            rejected: 0,
            unknown: 0
        });
        clearCurrentBasket();
    };

    const resendBetDoneFn = () => {
        setIsShowPreviewInfo(true);
        setIsShowConfirmation(false);
        setSendBetTime(null);

        setConfirmationStatus({
            status: null,
            accepted: 0,
            rejected: 0,
            unknown: 0
        });
        clearCurrentBasket({isResend: true});
    };

    const disabledResendBtn = () => {
        const resendBetLines = betBaskets[currentBasket]?.find((i) => i.isCounterOffer);
        return !resendBetLines;
    };

    return {
        betBaskets,
        setBetBaskets,
        addToBetSlip,
        removeBetLinesItem,
        updateBetLinespData,
        panelAllupObj: panelAllupArr[currentBasket],
        resetPanelAllupFn,
        panelAllupCheckFn,
        computePanelAllupFunc,
        isShowPreviewInfo,
        setIsShowPreviewInfo,
        isShowFTInplayDialog,
        setIsShowFTInplayDialog,
        clearBetSlip,
        updateBetTotal,
        getBetTotal,
        isShowError,
        setIsShowError,
        panelAllupData,
        setPanelAllupData,
        currentBasket,
        betLines: betBaskets[currentBasket],
        setCurrentBasket,
        clearCurrentBasket,
        sendBetRequest,
        isShowConfirmation,
        setIsShowConfirmation,
        betDoneFn,
        sendBetTime,
        confirmationStatus,
        balance,
        setBalance,
        disabledResendBtn: disabledResendBtn(),
        updateResendBetLine,
        resendBetDoneFn,
        isSpeedbet,
        setIsSpeedbet,
        sendBetAPILoading: globalContext.globalState.isSendingBet,
        betBaskets_0,
        firstTimeFromJCRWRedirect,
        GetXPoolAllUpEnabled,
        TranslateBetType,
        disablePreviewConfirm,
        setDisablePreviewConfirm
    };
};

const updateBetLineOdds = (betLine, resultBetInfo) => {
    const betObj = betLine.betObj;
    const newInvestment = resultBetInfo?.newInvestment;
    const newAmount = newInvestment?.proposedAmount;
    if (resultBetInfo?.counterOfferType == '2' || resultBetInfo?.counterOfferType == '3') {
        // amount changed
        betLine.oldUnitBet = betLine.unitBet;
        betLine.unitBet = newAmount?.replace('$', '');
        betLine.counterOfferAmountChange = true;
    }
    if (betObj?.betType != 'ALUP') {
        //
        betLine.betObj.combs = betObj.combs.map((comb) => {
            const currNewOdds = window.globalConfig.IS_IB
                ? newInvestment?.newOdds?.find(
                      (oddsObj) =>
                          comb.poolId == oddsObj.poolId &&
                          comb.combId == oddsObj.combId &&
                          comb.lineId == oddsObj.lineId
                  )
                : newInvestment?.newOdds?.find(
                      (oddsObj) =>
                          comb.poolId == oddsObj.poolId && comb.combId == oddsObj.cmdId && comb.lineId == oddsObj.lindId
                  );
            if (currNewOdds) {
                const oldOddsVal = comb.oddsVal || comb.currentOdds; // football || JKC&TNC
                return {
                    ...comb,
                    oldOddsVal,
                    oddsVal: window.globalConfig.IS_IB ? currNewOdds.oddsVal : currNewOdds.value, // football
                    currentOdds: window.globalConfig.IS_IB ? currNewOdds.oddsVal : currNewOdds.value // JKC&TNC
                };
            }
            return comb;
        });
    } else {
        // All Up
        betLine.betObj.betList = betLine.betObj.betList.map((item, index) => {
            item.combs = item.combs.map((comb) => {
                const currNewOdds = newInvestment?.newOdds?.find(
                    (oddsObj) =>
                        comb.poolId == oddsObj.poolId && comb.combId == oddsObj.combId && comb.lineId == oddsObj.lineId
                );
                if (currNewOdds) {
                    const oldOddsVal = comb.oddsVal || comb.currentOdds; // football || JKC&TNC
                    return {
                        ...comb,
                        oldOddsVal,
                        oddsVal: window.globalConfig.IS_IB ? currNewOdds.oddsVal : currNewOdds.value, // football
                        currentOdds: window.globalConfig.IS_IB ? currNewOdds.oddsVal : currNewOdds.value // JKC&TNC
                    };
                }
                return comb;
            });
            return item;
        });
    }
};

export const AddM6SlipFunc = (data, context, getAlertShowMode) => {
    let m6Arr = [...data];
    const betLineList = [];
    const initDefaultUnitBet = sessionStorage.getItem('Mk6DefaultUnitBetAmount')
        ? Number(sessionStorage.getItem('Mk6DefaultUnitBetAmount'))
        : 10;

    m6Arr.map((item, index) => {
        const id = new Date().getTime() + '' + index;
        let betLineObj = {
            ...item,
            multidraw: item.multidraw ? item.multidraw - 0 : item.multDraw ? item.multDraw - 0 : 1,
            chance: item.chance ? item.chance - 0 : 1,
            unitBet: item.unitBet ? item.unitBet - 0 : initDefaultUnitBet,
            bankerList: item.bankerList || [],
            prod: 'M6',
            betType: 'MK6',
            pageName: context.pageName,
            nextDraw: context.nextDraw,
            id,
            noOfBets: item.chance ? item.chance - 0 : 1
        };
        if (item.numList.length > 0) {
            if (
                betLineObj?.nextDraw?.snowballCode?.length == 0 ||
                (betLineObj?.nextDraw?.snowballCode?.length > 0 && sessionStorage.getItem('MK6SBDraw') == '1')
            ) {
                betLineList.push(betLineObj);
            }
            if((betLineObj?.nextDraw?.snowballCode?.length > 0 && sessionStorage.getItem('MK6SBDraw') != '1')){
                getAlertShowMode(context.translation('LB_BS_ADDTOSLIP_ERR_NOT_AVAILABLE'));
            }
        }
    });
    return betLineList;
};
