import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AddSlipBtn from '../Common/AddSlipBtn';
import OddsTable from '../Common/RCOddsTableCompact';
import OddsDropLegend from '../Common/OddsDropLegend';
import InvestmentCalculator from '../Common/InvestmentCalculator';
import Investment from '../Common/Investment';
import { getComingleMsgs, getMaxRunnerSize, getIsShowPmuText } from '../Common/Common';
import ComingleMsg from '../Common/ComingleMsg'
import MultiLegSlider from '../Common/MultiLegSlider';
import { RacingContext } from '../../Home/RacingPage';
import RefreshTime from '../../Common/ui-components/RefreshTime';
import Loading from '../../Common/ui-components/Loading';

const OddsDT = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const [isLogin, setIsLogin] = useState(true);

    const RCColumns = {
        no: true,
        banker1: true,
        banker2: false,
        banker3: false,
        leg: true,
        horseName: true,
        rcNoteM: false,
        win: true,
        place: false,
        field: true,
        banker1F: false,
        banker2F: false,
        banker3F: false
    }

    const RCColumnLbls = {
        banker1: t('LB_RC_ODDS_BANKER'),
        leg: t('LB_RC_ODDS_SEL')
    }

    if (context == null) {
        return <Loading/>
    } else {
        const mtg = context.content.meeting;
        let pool =  mtg.pmPools.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType=="DT")[0];
        if ( pool==null )
            return null;

        let legList = pool.leg.races;
        const defTableSize = Math.max(14, getMaxRunnerSize(context, legList, 0, 3));

        let tmpMsgs = getComingleMsgs(mtg, context.content.page, context.content.raceNo);
        const isShowPmu = getIsShowPmuText(mtg, legList)
        let comingleMsg1 = [];
        if (tmpMsgs != null) {
            let comingleLang = i18n.language == "en" ? "E" : "C";
            if (tmpMsgs['NOTE1_' + comingleLang] != '')
                comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}1: </span>{tmpMsgs['NOTE1_' + comingleLang]}</div>);
            if (tmpMsgs['NOTE2_' + comingleLang] != '')
                comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}2: </span>{tmpMsgs['NOTE2_' + comingleLang]}</div>);
            if (tmpMsgs['NOTE3_' + comingleLang] != '')
                comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}3: </span>{tmpMsgs['NOTE3_' + comingleLang]}</div>);
        }

        return (
            <section className={context.content.page}>
                <Investment betType={context.content.page} showJpt={true} showEstDiv={true} />
                <InvestmentCalculator className='border-radius-down' enable={true} />
                <RefreshTime product="racing" />
                <MultiLegSlider cnt={legList.length} className="multi-leg-2x-table">
                    {legList.map((_cItem, _cIndex) => {
                        return <OddsTable betType={context.content.page} columns={RCColumns} lbl={RCColumnLbls}
                            firstRace={legList[0]} raceNo={_cItem} startCnt={1} legIdx={_cIndex}
                            selectedBetType={context.content.page} showRaceHeader="true" defTableSize={defTableSize} />
                    })}
                </MultiLegSlider>
                {isShowPmu && <div className="rc-comingle-remarks"><ComingleMsg></ComingleMsg></div>}

                <OddsDropLegend />
                <div className="multi-leg-footer-betSlip">
                    <div><AddSlipBtn /></div>
                </div>
                <InvestmentCalculator className='border-radius-down' enable={!context.windowSize.isMobileSize} />
            </section>
        );
    }
}
export default OddsDT;